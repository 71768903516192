// src/components/ApplicationList.js
import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import './AdminPage.css';

function ApplicationList() {
  const [apps, setApps] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchApps = async () => {
      const snapshot = await getDocs(collection(db, 'patentApplications'));
      setApps(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };
    fetchApps();
  }, []);

  const formatDate = (timestamp) =>
    timestamp?.seconds ? new Date(timestamp.seconds * 1000).toLocaleDateString() : 'N/A';

  const handleDelete = async (app, e) => {
    e.stopPropagation();
    if (app.submitted || app.orderId) {
      alert("This application has already been submitted and cannot be deleted. There's an order for this application.");
      return;
    }
    if (window.confirm("Are you sure you want to delete this application?")) {
      if (window.confirm("This action cannot be undone. Confirm delete?")) {
        try {
          await deleteDoc(doc(db, 'patentApplications', app.id));
          setApps(apps.filter(a => a.id !== app.id));
        } catch (error) {
          console.error("Error deleting application: ", error);
        }
      }
    }
  };

  const handleView = (app, e) => {
    e.stopPropagation();
    navigate(`/admin/applications/${app.id}`, { state: { app } });
  };

  return (
    <div className="admin-page">
      <h2>📄 All Applications ({apps.length})</h2>
      <table className="application-table">
        <thead>
          <tr>
            <th className="col-app-title">Invention Title</th>
            <th className="col-patent-type">Patent Type</th>
            <th className="col-inventor">Inventor</th>
            <th className="col-created">Created</th>
            <th className="col-app-actions">Actions</th>
          </tr>
        </thead>
        <tbody>
          {apps.map(app => {
            const inventor = (
              `${app.inventors?.[0]?.firstName ?? ''} ${app.inventors?.[0]?.lastName ?? ''}`
            ).trim() || 'Unknown';
            return (
              <tr key={app.id}>
                <td className="col-app-title">{app.inventionTitle || 'Untitled Patent'}</td>
                <td className="col-patent-type">{app.patentType || 'N/A'}</td>
                <td className="col-inventor">{inventor}</td>
                <td className="col-created">{formatDate(app.createdAt)}</td>
                <td className="col-app-actions actions">
                  <button 
                    className="icon-button view-button"
                    onClick={(e) => handleView(app, e)}
                    title="View application"
                  >
                    <i className="fas fa-eye"></i>
                  </button>
                  <button
                    className="icon-button delete-button"
                    onClick={(e) => handleDelete(app, e)}
                    title="Delete application"
                  >
                    <i className="fas fa-trash-alt"></i>
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ApplicationList;