// src/components/Resources.js
import React from 'react';
import { Link } from 'react-router-dom';
import './StaticPages.css'; // Use unified stylesheet

const blogPosts = [
  {
    id: 1,
    title: "Understanding the Basics of Patent Filing",
    excerpt: "Learn the fundamental steps involved in filing a patent and how to navigate the process with confidence.",
    content: "Filing a patent involves several key steps—from determining patentability and preparing your documentation to filing with the relevant authorities. In this article, we provide an in-depth overview of each stage, practical tips, and common pitfalls to avoid."
  },
  {
    id: 2,
    title: "Strategies for Protecting Your Intellectual Property",
    excerpt: "Discover key strategies to safeguard your innovative ideas and ensure your intellectual property remains secure.",
    content: "Protecting your intellectual property requires a strategic approach. This guide explores different strategies including patent portfolios, licensing agreements, and defensive publications. It also highlights common legal challenges and how to overcome them."
  },
  {
    id: 3,
    title: "Trends in Patent Litigation and Industry Developments",
    excerpt: "An overview of current trends in patent litigation and how industry developments are shaping the future of intellectual property.",
    content: "Patent litigation and industry trends are evolving rapidly. This article analyzes recent cases, discusses the impact of emerging technologies on patent disputes, and offers insights into what innovators can expect in the near future."
  },
  {
    id: 4,
    title: "How to Prepare a Robust Patent Application",
    excerpt: "Tips and best practices for preparing a strong patent application to maximize your chances of success.",
    content: "A robust patent application is critical to protecting your invention. This detailed guide covers essential documentation, strategies for drafting claims, and advice on avoiding common mistakes that can jeopardize your application."
  },
  {
    id: 5,
    title: "Navigating International Patent Laws",
    excerpt: "A guide to understanding international patent laws and protecting your innovation in a global market.",
    content: "In a global market, navigating international patent laws can be complex. This article offers practical advice on filing patents in multiple jurisdictions, discusses treaties and agreements, and highlights key considerations for multinational protection."
  },
];

function Resources() {
  return (
    <div className="resources-page static-page-wrapper">
      <div className="resources-container static-page">
        <h1>Resources & Blog</h1>
        <p>
          Explore our collection of articles, guides, and tips on intellectual property, patent strategy, and industry trends.
          Our aim is to empower innovators with the knowledge and insights needed to protect and grow their ideas.
        </p>
        <div className="blog-list">
          {blogPosts.map((post) => (
            <div key={post.id} className="blog-post">
              <h2>{post.title}</h2>
              <p>{post.excerpt}</p>
              <Link to={`/resources/${post.id}`}>Read More</Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Resources;
