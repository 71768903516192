// src/components/About.js
import React from 'react';
import StaticHeader from './StaticHeader';
import './StaticPages.css';

function About() {
  return (
    <div className="static-page">
      <StaticHeader />
      <h1>About Patent Ease</h1>
      <p>
        At Patent Ease, we’re on a mission to simplify the patent application process and make it accessible for every innovator.
        Whether you're a first-time inventor or a seasoned entrepreneur, we provide the tools, resources, and expert support you need
        to protect your ideas with confidence.
      </p>
      <p>
        Our platform guides you step-by-step through the patent filing process—from drafting your application to producing
        polished, professional-grade documents. We take the guesswork out of legal procedures so you can focus on what you do best: innovating.
      </p>
      <p>
        Built by a team of patent professionals, engineers, and tech experts, Patent Ease combines intelligent automation
        with years of industry knowledge. Our intuitive interface is designed to reduce complexity, save time, and help you avoid costly mistakes.
      </p>
      <p>
        We believe great ideas can come from anywhere. That’s why our goal is to empower inventors from all backgrounds
        by offering an affordable, user-friendly solution for securing intellectual property.
      </p>
      <p>
        Join thousands of users who trust Patent Ease to simplify the path from inspiration to protection—and bring their innovations to life.
      </p>
    </div>
  );
}

export default About;
