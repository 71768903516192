import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

function Home() {
  return (
    <div className="home-container">
      <header className="home-header">
        <h1>Patent Ease</h1>
        <p>Your DIY Patent Application Made Simple</p>
        <nav className="home-nav">
          <Link to="/login" className="nav-link">Login</Link>
          <Link to="/signup" className="nav-link">Sign Up</Link>
        </nav>
      </header>
      <section className="home-content">
        <h2>Welcome to Patent Ease</h2>
        <p>
          At Patent Ease, we empower inventors to easily create, save, and manage their patent applications.
          Our platform guides you through each step, making the daunting process of patent filing approachable 
          and efficient.
        </p>
        <p>
          Whether you're an individual inventor or a small business, our DIY system gives you the tools 
          to protect your innovation. Get started today and take control of your patent journey!
        </p>
        <Link to="/signup" className="cta-button">Get Started</Link>
      </section>
    </div>
  );
}

export default Home;
