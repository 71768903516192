// src/components/admin/AdminDashboard.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminDashboard.css';

function AdminDashboard() {
  const navigate = useNavigate();

  return (
    <div className="admin-home">
      <h2>📊 Admin Dashboard Overview</h2>
      <p>Welcome to the control center. Use the menu or boxes below to manage the system.</p>

      <div className="admin-stats">
        <div className="stat-box clickable" onClick={() => navigate('/admin/applications')}>
          <h3>Applications</h3>
          <p>View all patent applications</p>
        </div>
        <div className="stat-box clickable" onClick={() => navigate('/admin/orders')}>
          <h3>Orders</h3>
          <p>Review and update order status</p>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
