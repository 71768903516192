// src/components/admin/ApplicationDetailsAdmin.js
import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../firebase";
import "./AdminPage.css";

function ApplicationDetailsAdmin() {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [app, setApp] = useState(state?.app || null);
  const [loading, setLoading] = useState(!state?.app);

  // Form data structure including inventors as an array.
  const [formData, setFormData] = useState({
    inventionTitle: "",
    summary: "",
    patentType: "",
    fieldOfInvention: "",
    background: "",
    briefDrawingDescription: "",
    detailedDescription: "",
    drawing: "",
    inventors: [
      {
        firstName: "",
        middleName: "",
        lastName: "",
        address: "",
        email: "",
        phone: "",
        isMinor: false,
        guardian: {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        },
      },
    ],
  });

  useEffect(() => {
    if (!app && id) {
      const fetchApp = async () => {
        const snap = await getDoc(doc(db, "patentApplications", id));
        if (snap.exists()) {
          const data = { id: snap.id, ...snap.data() };
          setApp(data);
          setFormData({
            inventionTitle: data.inventionTitle || "",
            summary: data.summary || "",
            patentType: data.patentType || "",
            fieldOfInvention: data.fieldOfInvention || "",
            background: data.background || "",
            briefDrawingDescription: data.briefDrawingDescription || "",
            detailedDescription: data.detailedDescription || "",
            drawing: data.drawing || "",
            inventors:
              data.inventors && data.inventors.length > 0
                ? data.inventors
                : [
                    {
                      firstName: "",
                      middleName: "",
                      lastName: "",
                      address: "",
                      email: "",
                      phone: "",
                      isMinor: false,
                      guardian: {
                        firstName: "",
                        lastName: "",
                        email: "",
                        phone: "",
                      },
                    },
                  ],
          });
        }
        setLoading(false);
      };
      fetchApp();
    } else if (state?.app) {
      const data = state.app;
      setFormData({
        inventionTitle: data.inventionTitle || "",
        summary: data.summary || "",
        patentType: data.patentType || "",
        fieldOfInvention: data.fieldOfInvention || "",
        background: data.background || "",
        briefDrawingDescription: data.briefDrawingDescription || "",
        detailedDescription: data.detailedDescription || "",
        drawing: data.drawing || "",
        inventors:
          data.inventors && data.inventors.length > 0
            ? data.inventors
            : [
                {
                  firstName: "",
                  middleName: "",
                  lastName: "",
                  address: "",
                  email: "",
                  phone: "",
                  isMinor: false,
                  guardian: {
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                  },
                },
              ],
      });
    }
  }, [app, id, state]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle change for inventor fields; expects input names matching the field name
  // For guardian fields, use a dot notation (e.g. "guardian.firstName")
  const handleInventorChange = (index, e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => {
      const inventors = [...prev.inventors];
      if (name === "isMinor") {
        inventors[index][name] = checked;
      } else if (name.startsWith("guardian.")) {
        const guardianField = name.split(".")[1];
        inventors[index].guardian = {
          ...inventors[index].guardian,
          [guardianField]: value,
        };
      } else {
        inventors[index][name] = value;
      }
      return { ...prev, inventors };
    });
  };

  const addInventor = () => {
    setFormData((prev) => ({
      ...prev,
      inventors: [
        ...prev.inventors,
        {
          firstName: "",
          middleName: "",
          lastName: "",
          address: "",
          email: "",
          phone: "",
          isMinor: false,
          guardian: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
          },
        },
      ],
    }));
  };

  const removeInventor = (index) => {
    setFormData((prev) => ({
      ...prev,
      inventors: prev.inventors.filter((_, i) => i !== index),
    }));
  };

  const handleFileUpload = async (e) => {
    const uploadedFile = e.target.files[0];
    if (!uploadedFile) return;
    const storageRef = ref(storage, `drawings/${id}-${uploadedFile.name}`);
    await uploadBytes(storageRef, uploadedFile);
    const url = await getDownloadURL(storageRef);
    setFormData((prev) => ({ ...prev, drawing: url }));
    alert("Drawing uploaded successfully");
  };

  const handleUpdate = async () => {
    try {
      const updatedAppData = {
        inventionTitle: formData.inventionTitle,
        summary: formData.summary,
        patentType: formData.patentType,
        fieldOfInvention: formData.fieldOfInvention,
        background: formData.background,
        briefDrawingDescription: formData.briefDrawingDescription,
        detailedDescription: formData.detailedDescription,
        drawing: formData.drawing,
        inventors: formData.inventors,
      };

      await updateDoc(doc(db, "patentApplications", id), updatedAppData);
      alert("Application updated successfully!");
    } catch (err) {
      console.error("Error updating application:", err);
      alert("Failed to update.");
    }
  };

  if (loading) return <p className="admin-page">Loading application...</p>;
  if (!app) return <p className="admin-page">Application not found.</p>;

  return (
    <div className="admin-page">
      <h2>📝 Manage Application</h2>

      <form className="admin-form">
        <div className="form-group">
          <label>Title of Invention</label>
          <input
            type="text"
            name="inventionTitle"
            value={formData.inventionTitle}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Patent Type</label>
          <select
            name="patentType"
            value={formData.patentType}
            onChange={handleChange}
          >
            <option value="">Select Type</option>
            <option value="Provisional Utility">Provisional Utility</option>
            <option value="Non-Provisional Utility">Non-Provisional Utility</option>
            <option value="Design">Design</option>
          </select>
        </div>

        <div className="form-group">
          <label>Field of Invention</label>
          <input
            type="text"
            name="fieldOfInvention"
            value={formData.fieldOfInvention}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Background</label>
          <textarea
            name="background"
            rows="3"
            value={formData.background}
            onChange={handleChange}
          ></textarea>
        </div>

        <div className="form-group">
          <label>Summary</label>
          <textarea
            name="summary"
            rows="3"
            value={formData.summary}
            onChange={handleChange}
          ></textarea>
        </div>

        <div className="form-group">
          <label>Drawing Description</label>
          <textarea
            name="briefDrawingDescription"
            rows="3"
            value={formData.briefDrawingDescription}
            onChange={handleChange}
          ></textarea>
        </div>

        <div className="form-group">
          <label>Detailed Description</label>
          <textarea
            name="detailedDescription"
            rows="5"
            value={formData.detailedDescription}
            onChange={handleChange}
          ></textarea>
        </div>

        <div className="form-group">
          <label>Upload Drawing</label>
          <input type="file" onChange={handleFileUpload} />
          {formData.drawing && (
            <div style={{ marginTop: "10px" }}>
              <img
                src={formData.drawing}
                alt="Drawing Preview"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          )}
        </div>

        <h3>Inventors</h3>
        {formData.inventors.map((inventor, index) => (
          <div key={index} className="inventor-section">
            <h4>
              Inventor {index + 1}{" "}
              {formData.inventors.length > 1 && (
                <button
                  type="button"
                  onClick={() => removeInventor(index)}
                  className="remove-inventor-button"
                >
                  Remove
                </button>
              )}
            </h4>
            <div className="form-group">
              <label>First Name</label>
              <input
                type="text"
                name="firstName"
                value={inventor.firstName}
                onChange={(e) => handleInventorChange(index, e)}
                required
              />
            </div>
            <div className="form-group">
              <label>Middle Name (optional)</label>
              <input
                type="text"
                name="middleName"
                value={inventor.middleName}
                onChange={(e) => handleInventorChange(index, e)}
              />
            </div>
            <div className="form-group">
              <label>Last Name</label>
              <input
                type="text"
                name="lastName"
                value={inventor.lastName}
                onChange={(e) => handleInventorChange(index, e)}
                required
              />
            </div>
            <div className="form-group">
              <label>Address</label>
              <input
                type="text"
                name="address"
                value={inventor.address}
                onChange={(e) => handleInventorChange(index, e)}
              />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={inventor.email}
                onChange={(e) => handleInventorChange(index, e)}
              />
            </div>
            <div className="form-group">
              <label>Phone</label>
              <input
                type="tel"
                name="phone"
                value={inventor.phone}
                onChange={(e) => handleInventorChange(index, e)}
              />
            </div>
            <div className="form-group">
              <label>
                <input
                  type="checkbox"
                  name="isMinor"
                  checked={inventor.isMinor}
                  onChange={(e) => handleInventorChange(index, e)}
                />{" "}
                Inventor is a minor
              </label>
            </div>
            {inventor.isMinor && (
              <>
                <div className="form-group">
                  <label>Guardian First Name</label>
                  <input
                    type="text"
                    name="guardian.firstName"
                    value={inventor.guardian?.firstName || ""}
                    onChange={(e) => handleInventorChange(index, e)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Guardian Last Name</label>
                  <input
                    type="text"
                    name="guardian.lastName"
                    value={inventor.guardian?.lastName || ""}
                    onChange={(e) => handleInventorChange(index, e)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Guardian Email</label>
                  <input
                    type="email"
                    name="guardian.email"
                    value={inventor.guardian?.email || ""}
                    onChange={(e) => handleInventorChange(index, e)}
                  />
                </div>
                <div className="form-group">
                  <label>Guardian Phone</label>
                  <input
                    type="tel"
                    name="guardian.phone"
                    value={inventor.guardian?.phone || ""}
                    onChange={(e) => handleInventorChange(index, e)}
                  />
                </div>
              </>
            )}
          </div>
        ))}
        <button type="button" onClick={addInventor} className="add-inventor-button">
          + Add Inventor
        </button>

        <button
          type="button"
          className="view-app-button"
          onClick={handleUpdate}
        >
          💾 Save Changes
        </button>

        <div className="section link-section">
          <button
            type="button"
            className="back-button"
            onClick={() => navigate("/admin/applications")}
          >
            ← Back to Applications
          </button>
        </div>
      </form>
    </div>
  );
}

export default ApplicationDetailsAdmin;
