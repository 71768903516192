import React from 'react';
import StaticHeader from './StaticHeader';
import './StaticPages.css';

function CookiePolicy() {
  return (
    <div className="static-page">
      <StaticHeader />
      <h1>Cookie Policy</h1>
      <p>Effective Date: March 28, 2025</p>

      <p>
        Patent Ease uses cookies and similar tracking technologies to enhance your browsing experience, analyze site traffic, and assist in our marketing efforts.
        This Cookie Policy explains what cookies are, how we use them, and your options regarding cookie usage.
      </p>

      <h2>1. What Are Cookies?</h2>
      <p>
        Cookies are small text files that are placed on your computer or mobile device when you visit a website.
        They are widely used to make websites work efficiently, as well as to provide information to the website owners.
      </p>

      <h2>2. How We Use Cookies</h2>
      <p>
        We use cookies for the following purposes:
      </p>
      <ul>
        <li>
          <strong>Essential Cookies:</strong> These cookies are necessary for the website to function and cannot be switched off in our systems.
        </li>
        <li>
          <strong>Analytics Cookies:</strong> We use these cookies to help us understand how our website is being used and to improve its functionality.
        </li>
        <li>
          <strong>Marketing Cookies:</strong> These cookies are used to deliver advertisements that are relevant to you and to measure the effectiveness of our advertising campaigns.
        </li>
      </ul>

      <h2>3. Third-Party Cookies</h2>
      <p>
        In addition to our own cookies, we may also use various third-party cookies to report usage statistics, deliver advertisements, and provide other services on the Site.
        These cookies are subject to the privacy policies of the third parties.
      </p>

      <h2>4. Your Cookie Choices</h2>
      <p>
        You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer.
        Please note that if you disable cookies, some parts of our website may not function properly.
      </p>

      <h2>5. Changes to This Cookie Policy</h2>
      <p>
        We may update this Cookie Policy from time to time. Any changes will be posted on this page with an updated effective date.
      </p>

      <h2>6. Contact Us</h2>
      <p>
        If you have any questions about this Cookie Policy, please contact us at:
      </p>
      <p>
        <strong>Email:</strong> <a href="mailto:support@patent-ease.com">support@patent-ease.com</a><br />
        <strong>Phone:</strong> (909) 200-4050<br />
        <strong>Address:</strong> 1252 S Diamond Bar Blvd, Unit D, Diamond Bar, CA 91765
      </p>
    </div>
  );
}

export default CookiePolicy;
