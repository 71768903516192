// src/components/admin/AdminProfile.js
import { useState, useEffect } from 'react';
import { auth } from '../../firebase';
import { 
  onAuthStateChanged, 
  updateProfile,
  sendPasswordResetEmail 
} from 'firebase/auth';
import './AdminProfile.css';

function AdminProfile() {
  const [user, setUser] = useState(null);
  const [newName, setNewName] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        setNewName(currentUser.displayName || '');
      }
    });
    return unsubscribe;
  }, []);

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    setMessage('');

    try {
      await updateProfile(auth.currentUser, { displayName: newName });
      setMessage('Profile updated successfully!');
    } catch (error) {
      setMessage(`Error updating profile: ${error.message}`);
    }
  };

  const handlePasswordReset = async () => {
    if (!user?.email) {
      setMessage('No email address found for this account');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, user.email);
      setMessage('Password reset email sent! Check your inbox.');
    } catch (error) {
      setMessage(`Error sending reset email: ${error.message}`);
    }
  };

  return (
    <div className="admin-profile-page">
      <h2>Admin Profile</h2>
      {user && (
        <div className="admin-profile-content">
          <form onSubmit={handleUpdateProfile} className="admin-profile-form">
            <div className="form-group">
              <label>Name</label>
              <input 
                type="text" 
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                placeholder="Enter your name"
                required 
              />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input type="email" value={user.email} readOnly />
            </div>
            <button type="submit">Update Profile</button>
          </form>

          <div className="password-reset-section">
            <h3>Change Password</h3>
            <p>
              Click the button below to receive a password reset link in your email:
            </p>
            <button 
              onClick={handlePasswordReset}
              className="password-reset-btn"
            >
              Send Password Reset Email
            </button>
          </div>
        </div>
      )}
      {message && <p className="update-message">{message}</p>}
    </div>
  );
}

export default AdminProfile;