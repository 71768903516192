// src/components/ApplicationDetails.js
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./ApplicationDetails.css";

function ApplicationDetails() {
  const { state } = useLocation();
  const { app } = state || {};
  const navigate = useNavigate();

  if (!app)
    return <p className="loading-message">No application data available.</p>;

  const isSubmitted = app.submitted;

  const formatDate = (date) => {
    if (!date) return "N/A";
    const d = new Date(date);
    return isNaN(d) ? "N/A" : d.toLocaleString();
  };

  return (
    <div className="application-details-container">
      <div className="application-card">
        <h2>📄 {app.inventionTitle || "Untitled Patent"}</h2>
        {app.applicationNumber && (
          <p className="application-number">
            Application #{app.applicationNumber}
          </p>
        )}

        <div className="section">
          <h3>🧠 Patent Info</h3>
          <p>
            <strong>Patent Type:</strong> {app.patentType || "Not specified"}
          </p>
          <p>
            <strong>Field of Invention:</strong>{" "}
            {app.fieldOfInvention || "Not specified"}
          </p>
          <p>
            <strong>Date Created:</strong> {formatDate(app.createdAt)}
          </p>
        </div>

        <div className="section">
          <h3>👥 Inventors</h3>
          {app.inventors && app.inventors.length > 0 ? (
            app.inventors.map((inventor, index) => (
              <div key={index} className="inventor-details">
                <p>
                  <strong>First Name:</strong> {inventor.firstName || "N/A"}
                </p>
                {inventor.middleName && (
                  <p>
                    <strong>Middle Name:</strong> {inventor.middleName}
                  </p>
                )}
                <p>
                  <strong>Last Name:</strong> {inventor.lastName || "N/A"}
                </p>
                <p>
                  <strong>Inventor is a minor:</strong>{" "}
                  {inventor.isMinor ? "Yes" : "No"}
                </p>
                {inventor.isMinor && inventor.guardian && (
                  <div className="guardian-details">
                    <h4>🧑‍⚖️ Guardian Information</h4>
                    <p>
                      <strong>First Name:</strong>{" "}
                      {inventor.guardian.firstName || "N/A"}
                    </p>
                    <p>
                      <strong>Last Name:</strong>{" "}
                      {inventor.guardian.lastName || "N/A"}
                    </p>
                    {inventor.guardian.email && (
                      <p>
                        <strong>Email:</strong> {inventor.guardian.email}
                      </p>
                    )}
                    {inventor.guardian.phone && (
                      <p>
                        <strong>Phone:</strong> {inventor.guardian.phone}
                      </p>
                    )}
                  </div>
                )}
              </div>
            ))
          ) : (
            <p>No inventor information available.</p>
          )}
        </div>

        <div className="section">
          <h3>📚 Background</h3>
          <p>{app.background || "Not specified"}</p>
        </div>

        <div className="section">
          <h3>📝 Summary</h3>
          <p>{app.summary || "Not specified"}</p>
        </div>

        <div className="section">
          <h3>🎨 Drawing Description</h3>
          <p>{app.briefDrawingDescription || "Not specified"}</p>
        </div>

        <div className="section">
          <h3>📖 Detailed Description</h3>
          <p>{app.detailedDescription || "Not specified"}</p>
        </div>

        {app.drawing && (
          <div className="section">
            <h3>🖼 Drawing</h3>
            <img
              src={app.drawing}
              alt="Patent Drawing"
              className="drawing-image"
            />
          </div>
        )}

        <div className="button-section">
          {!isSubmitted ? (
            <>
              <button
                className="action-button update"
                onClick={() =>
                  navigate("/dashboard/new-patent", { state: { app } })
                }
              >
                ✏️ Update Application
              </button>
              <button
                className="action-button checkout"
                onClick={() =>
                  navigate("/dashboard/checkout", { state: { app } })
                }
              >
                💳 Proceed to Checkout
              </button>
            </>
          ) : (
            <p className="submitted-message">
              ✅ This application has been submitted and cannot be modified.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ApplicationDetails;
