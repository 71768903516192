import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import SignUp from './components/SignUp';
import DashboardLayout from './components/DashboardLayout';
import AdminLayout from './components/admin/AdminLayout';
import Profile from './components/Profile';
import PatentForm from './components/PatentForm';
import MyApplications from './components/MyApplications';
import ApplicationDetails from './components/ApplicationDetails';
import Orders from './components/Orders';
import OrderDetails from './components/OrderDetails';
import Checkout from './components/Checkout';
import PaymentSuccess from './components/PaymentSuccess';
import About from './components/About';
import Contact from './components/Contact';
import Investors from './components/Investors';
import Press from './components/Press';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import Disclaimer from './components/Disclaimer';
import CookiePolicy from './components/CookiePolicy';
import FAQ from './components/FAQ';
import Resources from './components/Resources';
import ResourceDetail from './components/ResourceDetail';
import UsefulLinks from './components/UsefulLinks';
import Footer from './components/Footer';

// Admin Pages
import AdminDashboard from './components/admin/AdminDashboard';
import AdminProfile from './components/admin/AdminProfile';
import UserList from './components/admin/UserList';
import UserDetails from './components/admin/UserDetails';
import ApplicationList from './components/admin/ApplicationList';
import ApplicationDetailsAdmin from './components/admin/ApplicationDetailsAdmin';
import OrderList from './components/admin/OrderList';
import OrderDetailsAdmin from './components/admin/OrderDetailsAdmin';
import SubscriberList from './components/admin/SubscriberList';

import './App.css';

function App() {
  return (
    <Router>
      <div className="app-container">
        <Routes>
          {/* Public Pages */}
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/investors" element={<Investors />} />
          <Route path="/press" element={<Press />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/resources/:id" element={<ResourceDetail />} />
          <Route path="/useful-links" element={<UsefulLinks />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />

          {/* User Dashboard (With Sidebar) */}
          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route index element={<Profile />} />
            <Route path="profile" element={<Profile />} />
            <Route path="new-patent" element={<PatentForm />} />
            <Route path="my-applications" element={<MyApplications />} />
            <Route path="application-details" element={<ApplicationDetails />} />
            <Route path="orders" element={<Orders />} />
            <Route path="orders/:id" element={<OrderDetails />} />
            <Route path="checkout" element={<Checkout />} />
            <Route path="payment-success" element={<PaymentSuccess />} />
          </Route>

          {/* Admin Panel (With Sidebar Layout) */}
          <Route path="/admin" element={<AdminLayout />}>
            <Route index element={<AdminDashboard />} />
            <Route path="profile" element={<AdminProfile />} />
            <Route path="users" element={<UserList />} />
            <Route path="users/:id" element={<UserDetails />} />
            <Route path="applications" element={<ApplicationList />} />
            <Route path="applications/:id" element={<ApplicationDetailsAdmin />} />
            <Route path="orders" element={<OrderList />} />
            <Route path="orders/:id" element={<OrderDetailsAdmin />} />
            <Route path="subscribers" element={<SubscriberList />} />
          </Route>

          {/* Catch-all: Redirect unknown paths to Home */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
