// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyC-MCqxwje95HUn3TezaJ8ZTaLtNgEflNE",
  authDomain: "patentease-7cdb0.firebaseapp.com",
  projectId: "patentease-7cdb0",
  storageBucket: "patentease-7cdb0.firebasestorage.app",
  messagingSenderId: "977971512085",
  appId: "1:977971512085:web:5ab0b69e6d8744f2195f1c"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };