// src/components/admin/SubscriberList.js
import React, { useEffect, useState } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import './AdminPage.css';

function SubscriberList() {
  const [subscribers, setSubscribers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, 'subscribers'),
      (snapshot) => {
        const subs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setSubscribers(subs);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching subscribers: ", error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  const formatDate = (timestamp) => 
    timestamp?.seconds ? new Date(timestamp.seconds * 1000).toLocaleDateString() : 'N/A';

  return (
    <div className="admin-page">
      <h2>📧 Subscribers ({subscribers.length})</h2>
      {loading ? (
        <p>Loading subscribers...</p>
      ) : subscribers.length > 0 ? (
        <table className="application-table">
          <thead>
            <tr>
              <th>Email</th>
              <th>Subscription Date</th>
            </tr>
          </thead>
          <tbody>
            {subscribers.map((subscriber) => (
              <tr key={subscriber.id}>
                <td>{subscriber.email}</td>
                <td>{formatDate(subscriber.subscribedAt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No subscribers found.</p>
      )}
    </div>
  );
}

export default SubscriberList;