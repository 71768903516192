// src/components/TermsOfService.js
import React from 'react';
import StaticHeader from './StaticHeader';
import './StaticPages.css';

function TermsOfService() {
  return (
    <div className="static-page">
      <StaticHeader />
      <h1>Terms of Service</h1>
      <p>Effective Date: March 28, 2025</p>

      <p>
        Welcome to Patent Ease. These Terms of Service ("Terms") govern your access to and use of our website, products,
        and services. By using our platform, you agree to comply with and be bound by these Terms. If you do not agree, please do not use our services.
      </p>

      <h2>1. Eligibility</h2>
      <p>
        You must be at least 18 years old to use Patent Ease. By using our services, you represent and warrant that you meet
        this requirement and that any information you provide is accurate and complete.
      </p>

      <h2>2. User Accounts</h2>
      <p>
        To access certain features, you may be required to create an account. You are responsible for maintaining the confidentiality
        of your login credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use.
      </p>

      <h2>3. Acceptable Use</h2>
      <p>You agree not to use the services to:</p>
      <ul>
        <li>Violate any applicable law or regulation</li>
        <li>Infringe the intellectual property or privacy rights of others</li>
        <li>Transmit harmful code or engage in unauthorized access or disruption of the platform</li>
        <li>Use automated tools to scrape, access, or index any portion of the platform without permission</li>
      </ul>

      <h2>4. Intellectual Property</h2>
      <p>
        All content, software, and technology used on the platform are owned by or licensed to Patent Ease and protected under intellectual property laws.
        Users retain ownership of content they create, but grant Patent Ease a license to use such content solely for the purpose of providing services.
      </p>

      <h2>5. Service Availability</h2>
      <p>
        We strive to provide uninterrupted service, but we do not guarantee availability at all times. We may suspend or terminate service for maintenance,
        upgrades, or unforeseen issues without prior notice.
      </p>

      <h2>6. Payments and Subscriptions</h2>
      <p>
        Certain features may require payment or subscription. By purchasing a plan, you agree to the pricing, billing frequency, and refund policy
        provided at checkout. All payments are processed securely through third-party providers.
      </p>

      <h2>7. Termination</h2>
      <p>
        We reserve the right to suspend or terminate your account at our discretion if you violate these Terms or engage in harmful conduct.
        You may also close your account at any time by contacting us.
      </p>

      <h2>8. Disclaimers</h2>
      <p>
        Patent Ease is not a law firm and does not offer legal advice. The information and tools provided are for general informational purposes only.
        You are responsible for reviewing and filing your documents with the appropriate legal authorities.
      </p>

      <h2>9. Limitation of Liability</h2>
      <p>
        To the fullest extent permitted by law, Patent Ease and its affiliates shall not be liable for any indirect, incidental, special, or consequential
        damages arising from your use of the services, including loss of profits, data, or goodwill.
      </p>

      <h2>10. Indemnification</h2>
      <p>
        You agree to defend, indemnify, and hold harmless Patent Ease, its officers, directors, employees, and agents from and against any claims, liabilities,
        damages, and expenses arising from your use of the services or violation of these Terms.
      </p>

      <h2>11. Changes to Terms</h2>
      <p>
        We may modify these Terms from time to time. If we make material changes, we will notify you via email or through the platform.
        Your continued use after the changes become effective constitutes your acceptance.
      </p>

      <h2>12. Governing Law</h2>
      <p>
        These Terms shall be governed by and construed in accordance with the laws of the State of California, without regard to its conflict of law principles.
        Any disputes shall be resolved in the state or federal courts located in Los Angeles County, California.
      </p>

      <h2>13. Contact Information</h2>
      <p>If you have any questions or concerns about these Terms, please contact us at:</p>
      <p>
        <strong>Email:</strong> <a href="mailto:support@patent-ease.com">support@patent-ease.com</a><br />
        <strong>Phone:</strong> (909) 200-4050<br />
        <strong>Address:</strong> 1252 S Diamond Bar Blvd, Unit D, Diamond Bar, CA 91765
      </p>
    </div>
  );
}

export default TermsOfService;
