// src/components/admin/UserDetails.js
import React, { useEffect, useState } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useParams } from 'react-router-dom';
import './AdminPage.css';

function UserDetails() {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      const snap = await getDoc(doc(db, 'users', id));
      if (snap.exists()) setUser({ id: snap.id, ...snap.data() });
    };
    fetchUser();
  }, [id]);

  const handleSave = async () => {
    setSaving(true);
    await updateDoc(doc(db, 'users', user.id), {
      displayName: user.displayName,
      role: user.role || 'user',
    });
    alert("User updated");
    setSaving(false);
  };

  if (!user) return <p>Loading...</p>;

  return (
    <div className="admin-section">
      <h2>Edit User</h2>
      <form className="admin-form">
        <div className="form-group">
          <label>Display Name</label>
          <input value={user.displayName || ''} onChange={(e) => setUser({ ...user, displayName: e.target.value })} />
        </div>
        <div className="form-group">
          <label>Role</label>
          <select value={user.role || 'user'} onChange={(e) => setUser({ ...user, role: e.target.value })}>
            <option value="user">User</option>
            <option value="admin">Admin</option>
          </select>
        </div>
        <button type="button" className="save-button" onClick={handleSave} disabled={saving}>
          {saving ? "Saving..." : "Save Changes"}
        </button>
      </form>
    </div>
  );
}

export default UserDetails;
