// src/components/Press.js
import React from 'react';
import StaticHeader from './StaticHeader';
import './StaticPages.css';

function Press() {
  return (
    <div className="static-page">
      <StaticHeader />
      <h1>Press</h1>
      <p>
        Welcome to the Patent Ease Press Center. This is your source for company announcements, media coverage, press releases, and news updates.
        Whether you're a journalist, blogger, or researcher, you'll find the latest insights into our mission, growth, and impact.
      </p>
      <p>
        Patent Ease is redefining how inventors protect their ideas—offering a smarter, faster, and more accessible way to file patents.
        Our story has been featured in innovation blogs, startup media, and IP industry outlets for our commitment to democratizing access to intellectual property services.
      </p>
      <h2>Media Resources</h2>
      <ul>
        <li>Latest press releases and company announcements</li>
        <li>Approved company boilerplate and executive bios</li>
        <li>Downloadable logos and brand assets</li>
        <li>Photos and screenshots of the Patent Ease platform</li>
        <li>Past media features and interviews</li>
      </ul>
      <h2>Contact Our Press Team</h2>
      <p>
        We welcome collaboration opportunities and press inquiries. If you're interested in covering Patent Ease or requesting an interview,
        please contact our media relations team:
      </p>
      <p>
        <strong>Email:</strong> <a href="mailto:press@patent-ease.com">press@patent-ease.com</a>
      </p>
      <p>
        We’re happy to provide official statements, schedule interviews, or share background on our company’s mission and milestones.
      </p>
    </div>
  );
}

export default Press;
