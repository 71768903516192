import React from 'react';
import StaticHeader from './StaticHeader';
import './StaticPages.css';

function FAQ() {
  return (
    <div className="faq-page">
      <StaticHeader />
      <div className="faq-container">
        <h1>Frequently Asked Questions</h1>
        <p>
          At Patent Ease, we are committed to simplifying the patent application process for every innovator.
          Below, we have compiled answers to the most common questions regarding patent filing, costs, timelines, legal considerations,
          and more. If you have further questions, please contact our support team.
        </p>

        <div className="faq-item">
          <h2>1. What is Patent Ease?</h2>
          <p>
            Patent Ease is an innovative platform designed to simplify the patent application process.
            Whether you’re a first-time inventor or a seasoned entrepreneur, we provide comprehensive tools, expert guidance,
            and resources to help you protect your intellectual property with confidence.
          </p>
        </div>

        <div className="faq-item">
          <h2>2. What types of patents can I file through Patent Ease?</h2>
          <p>
            Our platform focuses on utility and design patents. We guide you through the process of filing both provisional and non‑provisional
            utility patent applications, as well as design patents. Please note that we do not offer services for plant patents at this time.
          </p>
        </div>

        <div className="faq-item">
          <h2>3. What is the patent application process like?</h2>
          <p>
            The process involves several key stages:
          </p>
          <ul>
            <li><strong>Initial Consultation:</strong> Assessing the novelty and viability of your invention.</li>
            <li><strong>Preparation:</strong> Gathering detailed descriptions, drawings, and inventor information.</li>
            <li><strong>Filing:</strong> Submitting your application through our streamlined online process.</li>
            <li><strong>Examination:</strong> A patent examiner reviews your application and may request revisions.</li>
            <li><strong>Grant or Rejection:</strong> After review, your patent is either granted or you may need to respond to objections.</li>
          </ul>
          <p>
            We help you through each step to ensure your application is complete and robust.
          </p>
        </div>

        <div className="faq-item">
          <h2>4. How long does it take to obtain a patent?</h2>
          <p>
            Timelines vary widely depending on the complexity of your invention, the type of patent filed, and the workload of the patent office.
            Generally, the process can take anywhere from 12 months to several years. Our goal is to streamline the process and keep you informed every step of the way.
          </p>
        </div>

        <div className="faq-item">
          <h2>5. How much does it cost to file a patent application?</h2>
          <p>
            Costs depend on several factors including the type of patent, complexity of the invention, and legal fees.
            Our platform provides clear pricing details at each stage, so you understand the financial commitment before you proceed.
          </p>
        </div>

        <div className="faq-item">
          <h2>6. What legal considerations should I be aware of?</h2>
          <p>
            Key legal considerations include ensuring your invention is novel, non‑obvious, and useful.
            You should also consider potential infringement issues, licensing opportunities, and international protection if you plan to market your invention globally.
            Our expert team is available to help you navigate these complex legal matters.
          </p>
        </div>

        <div className="faq-item">
          <h2>7. What happens if my patent application is rejected?</h2>
          <p>
            In the event of a rejection, the patent office will provide an explanation in the form of an office action.
            You will then have an opportunity to respond by amending your application or providing additional information.
            We assist you in addressing these issues to improve the chances of a successful application.
          </p>
        </div>

        <div className="faq-item">
          <h2>8. Can I update my application after submission?</h2>
          <p>
            Once an application has been submitted, making changes is challenging. It is crucial to provide complete and accurate information from the start.
            If your application is still in draft form, however, our platform allows you to make changes before final submission.
          </p>
        </div>

        <div className="faq-item">
          <h2>9. How does Patent Ease support inventors?</h2>
          <p>
            Beyond providing a user-friendly application process, Patent Ease offers expert support, educational resources, and transparent pricing.
            Our mission is to empower you to protect your ideas with confidence and clarity.
          </p>
        </div>

        <div className="faq-item">
          <h2>10. Who can I contact for more information?</h2>
          <p>
            For further inquiries or personalized assistance, please contact our support team at <a href="mailto:support@patent-ease.com">support@patent-ease.com</a> or call us at (909) 200-4050.
          </p>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
