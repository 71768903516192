import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { auth, db } from '../firebase';
import { doc, runTransaction, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import './Checkout.css';

async function getNextOrderNumber() {
  const counterRef = doc(db, 'counters', 'orderCounter');
  return runTransaction(db, async (transaction) => {
    const counterSnap = await transaction.get(counterRef);
    let currentNumber = 10000;
    if (counterSnap.exists()) {
      currentNumber = counterSnap.data().current || 10000;
    }
    const nextNumber = currentNumber + 1;
    transaction.set(counterRef, { current: nextNumber });
    return nextNumber;
  });
}

function Checkout() {
  const [selectedPlan, setSelectedPlan] = useState('');
  const navigate = useNavigate();
  const { state } = useLocation();
  const { app } = state || {};

  if (!app) return <div className="checkout-container"><h2>No Application Data Found</h2></div>;

  const pricingOptions = [
    { 
      id: 'option1', 
      label: 'Basic - $199', 
      description: 'Uses our fine-tuned AI model (trained on thousands of successful patent applications) to generate your application draft. Includes PDF generation. Does not include USPTO application fees.' 
    },
    { 
      id: 'option2', 
      label: 'Expert - $399', 
      description: 'AI-generated application + patent agent expert review and revision. Ensures proper format and claims drafting. Does not include USPTO application fees.' 
    },
    { 
      id: 'option3', 
      label: 'Premium-Provisional - $665', 
      description: 'Full-service submission for Provisional Utility Patent (micro entity). Includes USPTO application fee. If micro entity requirements aren\'t met, we\'ll contact you to arrange additional fees.' 
    },
    { 
      id: 'option4', 
      label: 'Premium - $999', 
      description: 'Complete service for Non-Provisional Utility or Design Patent. Includes USPTO filing fee (micro entity). Additional fees required if not micro entity. Includes prior art search and unlimited revisions.' 
    }
  ];

  const getPlanLabel = () => {
    const match = pricingOptions.find(p => p.id === selectedPlan);
    return match ? match.label.split(' - ')[0] : '';
  };

  const getPlanAmount = () => {
    const match = pricingOptions.find(p => p.id === selectedPlan);
    return match ? match.label.match(/\$([\d.]+)/)?.[1] || "0" : "0";
  };

  const handlePaymentSuccess = async (details, data) => {
    try {
      const orderNumber = await getNextOrderNumber();
      const order = {
        userId: auth.currentUser.uid,
        plan: getPlanLabel(),
        amount: getPlanAmount(),
        transactionID: data.orderID,
        payerID: data.payerID,
        payerEmail: details.payer.email_address,
        paymentStatus: details.status,
        status: "Payment complete",
        applicationData: app,
        applicationLink: window.location.origin + "/application/" + data.orderID,
        orderDate: serverTimestamp(),
        orderNumber,
        ...(getPlanLabel() === 'Premium' && { usptoStatus: "Not submitted" })
      };

      await addDoc(collection(db, "orders"), order);

      alert("Payment successful and order created!");
      navigate('/dashboard/orders');
    } catch (error) {
      console.error("Order creation failed", error);
      alert("Payment succeeded but order saving failed: " + error.message);
    }
  };

  return (
    <div className="checkout-container">
      <h2>Choose Your Plan</h2>
      <div className="pricing-options">
        {pricingOptions.map((option) => (
          <div
            key={option.id}
            className={`pricing-option ${selectedPlan === option.id ? 'selected' : ''}`}
            onClick={() => setSelectedPlan(option.id)}
          >
            <h3>{option.label}</h3>
            <p>{option.description}</p>
          </div>
        ))}
      </div>

      {selectedPlan && (
        <div className="paypal-button-container">
          <PayPalScriptProvider
            options={{
              "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
              currency: "USD",
              intent: "capture"
            }}
          >
            <PayPalButtons
              createOrder={(data, actions) =>
                actions.order.create({
                  purchase_units: [{ amount: { value: getPlanAmount() } }]
                })
              }
              onApprove={(data, actions) =>
                actions.order.capture().then((details) => handlePaymentSuccess(details, data))
              }
              onError={(err) => {
                console.error("PayPal error", err);
                alert("Payment could not be processed.");
              }}
            />
          </PayPalScriptProvider>
        </div>
      )}
    </div>
  );
}

export default Checkout;
