import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './Orders.css';

function Orders() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const fetchOrders = async () => {
          try {
            const q = query(collection(db, 'orders'), where('userId', '==', user.uid)); 
            const snapshot = await getDocs(q);
            const ordersList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setOrders(ordersList);
          } catch (error) {
            console.error("Error fetching orders: ", error);
          } finally {
            setLoading(false);
          }
        };
        fetchOrders();
      } else {
        setLoading(false);
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  if (loading) return <p className="loading-message">Loading orders...</p>;

  return (
    <div className="orders-container">
      <h2>📦 My Orders</h2>
      {orders.length === 0 ? (
        <p className="empty-message">You haven't placed any orders yet.</p>
      ) : (
        <div className="orders-grid">
          {orders.map((order) => {
            const orderNumber = order.orderNumber || order.id.slice(0, 6).toUpperCase();
            const patentTitle = order.applicationData?.inventionTitle || "Untitled Patent";
            const date = order.orderDate ? new Date(order.orderDate).toLocaleDateString() : "N/A";

            return (
              <div
                key={order.id}
                className="order-card"
                onClick={() => navigate(`/dashboard/orders/${order.id}`, { state: { order } })}
              >
                <div className="order-card-header">
                  <span className="order-number">#{orderNumber}</span>
                  <span className="order-status">{order.status || 'Unknown'}</span>
                </div>
                <div className="order-body">
                  <h3 className="patent-title">{patentTitle}</h3>
                  <p className="order-date">📅 {date}</p>
                  <p className="order-amount">💵 ${order.amount || '0.00'}</p>
                </div>
                <button
                  className="details-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/dashboard/orders/${order.id}`, { state: { order } });
                  }}
                >
                  View Details →
                </button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default Orders;
