// src/components/Investors.js
import React from 'react';
import StaticHeader from './StaticHeader';
import './StaticPages.css';

function Investors() {
  return (
    <div className="static-page">
      <StaticHeader />
      <h1>Investors</h1>
      <p>
        Welcome to the Investor Relations section of Patent Ease. We’re pioneering the future of intellectual property
        through intuitive automation and expert-driven support, designed to empower inventors and entrepreneurs at every stage.
        Our goal is to become the most trusted platform in the patent services industry.
      </p>
      <p>
        As a mission-driven technology company, Patent Ease is focused on long-term value creation. Our strategy centers
        around product innovation, exceptional customer experience, and market expansion. We're committed to scaling
        responsibly while delivering measurable impact to both our users and stakeholders.
      </p>
      <p>
        Patent Ease operates at the intersection of legal technology and innovation enablement—an evolving space with high growth
        potential. We invite investors to explore how our solutions simplify a traditionally complex legal process and open new
        opportunities for creators everywhere.
      </p>
      <h2>Why Invest in Patent Ease</h2>
      <ul>
        <li>Strong market demand for accessible patent solutions</li>
        <li>Scalable SaaS business model with recurring revenue potential</li>
        <li>Experienced leadership with legal and technical expertise</li>
        <li>Focus on automation, AI, and user-centered design</li>
        <li>Early traction with individual inventors and startups</li>
      </ul>
      <h2>Get in Touch</h2>
      <p>
        We welcome potential investors, venture partners, and strategic collaborators who share our vision.
        For financial documents, growth metrics, or to schedule a meeting with our executive team, please reach out to:
      </p>
      <p>
        <strong>Email:</strong> <a href="mailto:investors@patent-ease.com">investors@patent-ease.com</a>
      </p>
    </div>
  );
}

export default Investors;
