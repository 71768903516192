// src/components/admin/AdminLayout.js
import React, { useEffect, useState } from 'react';
import { useNavigate, NavLink, Outlet } from 'react-router-dom';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase';
import {
  FiUser,
  FiFileText,
  FiShoppingCart,
  FiLogOut,
  FiMail
} from 'react-icons/fi';
import '../DashboardLayout.css';

function AdminLayout() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return unsubscribe;
  }, []);

  const handleLogout = async () => {
    await signOut(auth);
    navigate('/login');
  };

  return (
    <div className="dashboard-layout">
      <aside className="sidebar">
        <div className="sidebar-header">
          <h1>🛠 Admin Panel</h1>
          <p>Hi, {user ? (user.displayName || user.email) : 'Admin'}!</p>
        </div>

        <nav className="menu">
          <ul>
            <li>
              <NavLink
                to="/admin/profile"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <FiUser /> Profile
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/applications"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <FiFileText /> Applications
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/orders"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <FiShoppingCart /> Orders
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/subscribers"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <FiMail /> Subscribers
              </NavLink>
            </li>
          </ul>
        </nav>

        <div className="sidebar-actions">
          <button onClick={handleLogout}>
            <FiLogOut /> Logout
          </button>
        </div>
      </aside>

      <main className="main-content">
        <Outlet />
      </main>
    </div>
  );
}

export default AdminLayout;