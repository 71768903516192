import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, updateDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import './PaymentSuccess.css';

function PaymentSuccess() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { orderId } = state || {};
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!orderId) return navigate('/dashboard/orders');

    const syncOrder = async () => {
      try {
        // Use 'db' instead of 'firestore'
        const orderRef = doc(db, 'orders', orderId);
        
        // Update document with server timestamp
        await updateDoc(orderRef, {
          status: 'Payment complete',
          updatedAt: serverTimestamp()
        });

        // Fetch updated order
        const snap = await getDoc(orderRef);
        if (snap.exists()) {
          setOrder({ id: snap.id, ...snap.data() });
        }
      } catch (err) {
        console.error('PaymentSuccess error:', err);
        navigate('/dashboard/orders');
      } finally {
        setLoading(false);
      }
    };

    syncOrder();
  }, [orderId, navigate]);

  if (loading) return <div className="payment-success"><p>Loading...</p></div>;

  return (
    <div className="payment-success">
      <h2>Payment Successful!</h2>
      <p>Your order <strong>#{order.id}</strong> has been created.</p>
      <p><strong>Plan:</strong> {order.plan}</p>
      <p><strong>Status:</strong> {order.status}</p>
      {order.downloadLink && (
        <a 
          href={order.downloadLink} 
          target="_blank" 
          rel="noopener noreferrer"
          className="download-link"
        >
          View Your Application
        </a>
      )}
      <button 
        onClick={() => navigate('/dashboard/orders')}
        className="orders-button"
      >
        Go to My Orders
      </button>
    </div>
  );
}

export default PaymentSuccess;