// src/components/StaticHeader.js
import React from 'react';
import { Link } from 'react-router-dom';
import './StaticHeader.css';

function StaticHeader() {
  return (
    <header className="static-header">
      <nav className="header-container">
        <Link to="/" className="logo-link">
          <h1 className="logo-text">Patent Ease</h1>
        </Link>
      </nav>
    </header>
  );
}

export default StaticHeader;