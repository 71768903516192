// src/components/Contact.js
import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import StaticHeader from './StaticHeader';
import './Contact.css';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    emailjs.send(
      'service_zyktl37',
      'template_r90tm26',
      {
        to_email: 'lilian@mxk8us.com',
        from_name: formData.name,
        from_email: formData.email,
        message: `Name: ${formData.name}\nEmail: ${formData.email}\nMessage: ${formData.message}`
      },
      'P3g42M8A8_DiQLcDf'
    )
    .then((result) => {
      setStatusMessage('Message sent successfully!');
      setFormData({ name: '', email: '', message: '' });
    })
    .catch((error) => {
      setStatusMessage('Failed to send message. Please try again.');
    })
    .finally(() => {
      setIsSubmitting(false);
      setTimeout(() => setStatusMessage(''), 5000);
    });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="contact-page">
      <StaticHeader />
      <div className="contact-container">
        <div className="contact-header">
          <h1>Contact Us</h1>
          <p className="contact-subtitle">
            Get in touch with us! Send your questions, feedback, or support requests using the form below.
          </p>
        </div>
        
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              placeholder="Enter your full name"
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              placeholder="Enter your email address"
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows="6"
              required
              placeholder="Type your message here..."
            />
          </div>

          <button 
            type="submit" 
            className="submit-btn"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Sending...' : 'Send Message'}
          </button>

          {statusMessage && (
            <div className={`status-message ${statusMessage.includes('success') ? 'success' : 'error'}`}>
              {statusMessage}
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default Contact;