// src/components/ResourceDetail.js
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import './StaticPages.css';

const blogPosts = [
  {
    id: 1,
    title: "Understanding the Basics of Patent Filing",
    excerpt: "Learn the fundamental steps involved in filing a patent and how to navigate the process with confidence.",
    content: `
Patent filing is a critical step in protecting your intellectual property. This comprehensive guide provides an in-depth look at the entire process, starting with evaluating your invention's novelty and non-obviousness.

### Initial Assessment  
Before filing, conduct a thorough prior art search to assess whether your invention is truly novel and non-obvious. This phase is essential to avoid costly mistakes and ensure your invention meets the criteria set by patent offices worldwide.

### Documentation  
A detailed, accurate description is paramount. Best practices include drafting clear claims, preparing technical drawings, and using checklists and templates to capture every critical detail.

### Filing Process  
Learn about the procedural steps—from preparing necessary forms and paying filing fees to using electronic filing systems. We detail timelines, costs, and practical tips to streamline your submission.

### Post-Filing Process  
After filing, your application enters an examination phase where patent examiners review your submission. Understand what to expect during office actions, how to respond to inquiries, and strategies for overcoming objections to ultimately secure your patent.

By following these guidelines, you can navigate the complexities of patent filing with increased confidence and efficiency.
`
  },
  {
    id: 2,
    title: "Strategies for Protecting Your Intellectual Property",
    excerpt: "Discover key strategies to safeguard your innovative ideas and ensure your intellectual property remains secure.",
    content: `
In today's competitive landscape, protecting your intellectual property (IP) is vital. This article outlines strategic approaches designed to safeguard your innovations effectively.

### Building a Patent Portfolio  
Developing a comprehensive patent portfolio deters competitors and enhances your market position. Learn how to file multiple patents strategically to cover various aspects of your technology.

### Licensing and Monetization  
Licensing agreements can generate revenue while maintaining control over your IP. Understand key negotiation strategies and the essential terms that should be included in licensing contracts.

### Defensive Publications  
If patenting isn’t the best route, consider defensive publications. Disclosing your invention publicly can establish prior art and prevent others from patenting similar ideas.

### Trade Secrets and Confidentiality  
Not every innovation should be patented. Explore how trade secret laws, non-disclosure agreements, and robust security measures can protect sensitive information.

### International Protection  
Extend your IP protection globally by understanding international treaties and filing strategies. This roadmap helps you secure global rights while managing costs effectively.

Implementing these strategies provides a multi-layered defense for your intellectual property, ensuring that your innovations remain secure.
`
  },
  {
    id: 3,
    title: "Trends in Patent Litigation and Industry Developments",
    excerpt: "An overview of current trends in patent litigation and how industry developments are shaping the future of intellectual property.",
    content: `
The landscape of patent litigation is continually evolving due to technological advances and regulatory changes. This article provides a comprehensive analysis of current trends and insights into the future of patent law.

### Recent Landmark Cases  
Examine landmark cases that have reshaped legal precedents and understand their broader impact on the industry.

### Impact of Emerging Technologies  
Discover how technologies like AI, biotechnology, and IoT are challenging traditional patent frameworks and prompting legal reforms.

### Regulatory and Legislative Updates  
Stay informed about new regulations and legislative proposals affecting patent enforcement and business practices.

### Strategic Implications for Innovators  
Learn how inventors and companies are adapting their IP strategies amid increased litigation risks and changing legal standards.

This resource is essential for legal professionals, innovators, and business leaders aiming to navigate the dynamic world of patent litigation.
`
  },
  {
    id: 4,
    title: "How to Prepare a Robust Patent Application",
    excerpt: "Tips and best practices for preparing a strong patent application to maximize your chances of success.",
    content: `
A robust patent application is fundamental to protecting your invention. This guide offers a step-by-step approach to crafting a patent application that stands up to rigorous examination.

### Comprehensive Documentation  
Develop a detailed and precise description of your invention. Learn best practices for drafting clear claims and preparing technical drawings.

### Collaboration with Experts  
Working with experienced patent attorneys and technical experts can significantly enhance your application. Get tips on selecting the right professionals and collaborating effectively.

### Review and Revision Process  
Before submission, review your application thoroughly to identify and correct potential weaknesses using our comprehensive checklist.

### Practical Examples and Case Studies  
Real-world examples illustrate successful patent applications and highlight common pitfalls, helping you maximize your chances of approval.

By following these guidelines, you can create a patent application that meets regulatory requirements and offers robust protection.
`
  },
  {
    id: 5,
    title: "Navigating International Patent Laws",
    excerpt: "A guide to understanding international patent laws and protecting your innovation in a global market.",
    content: `
Expanding your patent protection globally requires an understanding of the complex international legal landscape. This article provides a detailed guide on navigating international patent laws.

### The Patent Cooperation Treaty (PCT)  
Learn about the PCT process, which simplifies filing patents in multiple countries by providing a unified procedure and timeline.

### Regional Patent Systems  
Understand different regional systems, such as the European Patent Convention (EPC), and compare their benefits and challenges.

### National Patent Requirements  
Examine key differences between major jurisdictions—like the United States, Europe, and Asia—and receive strategic advice on where to file first.

### Cost Management and Strategic Planning  
International patent protection can be costly. Discover strategies for managing filing priorities, translation expenses, and leveraging regional agreements to optimize your global IP strategy.

This resource is essential for innovators looking to protect their intellectual property on an international scale.
`
  },
];

function ResourceDetail() {
  const { id } = useParams();
  const post = blogPosts.find((p) => p.id === Number(id));

  if (!post) {
    return (
      <div className="resources-page">
        <div className="resources-container">
          <h1>Article Not Found</h1>
          <p>The requested article does not exist.</p>
          <Link to="/resources">Back to Resources</Link>
        </div>
      </div>
    );
  }

  return (
    <div className="resources-page">
      <div className="resources-container">
        <h1>{post.title}</h1>
        <div className="resource-content">
          <ReactMarkdown>{post.content}</ReactMarkdown>
        </div>
        <Link to="/resources">Back to Resources</Link>
      </div>
    </div>
  );
}

export default ResourceDetail;
