// src/components/PrivacyPolicy.js
import React from 'react';
import StaticHeader from './StaticHeader';
import './StaticPages.css';

function PrivacyPolicy() {
  return (
    <div className="static-page">
      <StaticHeader />
      <h1>Privacy Policy</h1>
      <p>Effective Date: March 28, 2025</p>

      <p>
        At Patent Ease, your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your
        personal information when you visit our website or use our services.
      </p>

      <h2>1. Information We Collect</h2>
      <p>We may collect the following categories of information:</p>
      <ul>
        <li><strong>Personal Information:</strong> such as your name, email address, phone number, and mailing address.</li>
        <li><strong>Account Details:</strong> including login credentials and user settings.</li>
        <li><strong>Usage Data:</strong> including IP address, browser type, operating system, pages visited, and activity logs.</li>
        <li><strong>Payment Information:</strong> (when applicable) including billing address and partial payment details via secure processors.</li>
        <li><strong>Communication Data:</strong> such as support inquiries, feedback, or survey responses.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We use your information to:</p>
      <ul>
        <li>Provide, operate, and improve our services</li>
        <li>Send administrative emails, confirmations, and account notifications</li>
        <li>Respond to customer service inquiries</li>
        <li>Send updates, newsletters, and promotional content (with your consent)</li>
        <li>Analyze usage and optimize the performance of our platform</li>
      </ul>

      <h2>3. Sharing Your Information</h2>
      <p>We do not sell your personal information. We may share it with:</p>
      <ul>
        <li>Trusted third-party service providers who assist with hosting, analytics, support, and payments</li>
        <li>Legal authorities if required to comply with applicable laws or to protect our rights</li>
        <li>Successors in the event of a merger, acquisition, or business transfer</li>
      </ul>

      <h2>4. Cookies and Tracking Technologies</h2>
      <p>
        We use cookies and similar technologies to personalize your experience, track site usage, and improve our service.
        You can modify your cookie preferences through your browser settings.
      </p>

      <h2>5. Data Security</h2>
      <p>
        We implement industry-standard security practices, including encryption, access control, and monitoring.
        However, no online service is completely secure, and we cannot guarantee absolute protection.
      </p>

      <h2>6. Your Rights and Choices</h2>
      <p>Depending on your location, you may have the right to:</p>
      <ul>
        <li>Access, correct, or delete your personal data</li>
        <li>Withdraw consent for data processing</li>
        <li>Request data portability</li>
        <li>File a complaint with a data protection authority</li>
      </ul>
      <p>
        To exercise your rights, please contact us at <a href="mailto:support@patent-ease.com">support@patent-ease.com</a>.
      </p>

      <h2>7. Children’s Privacy</h2>
      <p>
        Our services are not directed to children under 13, and we do not knowingly collect personal information from children.
        If we become aware of such data, we will delete it immediately.
      </p>

      <h2>8. International Users</h2>
      <p>
        If you are accessing our service from outside the United States, please note that your data may be transferred,
        stored, and processed in the U.S., where our servers are located.
      </p>

      <h2>9. Changes to This Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. If significant changes are made, we will notify users via email
        or a prominent notice on our website. Your continued use of the service means acceptance of the updated policy.
      </p>

      <h2>10. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy or our data practices, please contact us at:
      </p>
      <p>
        <strong>Email:</strong> <a href="mailto:support@patent-ease.com">support@patent-ease.com</a><br />
        <strong>Phone:</strong> (909) 200-4050<br />
        <strong>Address:</strong> 1252 S Diamond Bar Blvd, Unit D, Diamond Bar, CA 91765
      </p>
    </div>
  );
}

export default PrivacyPolicy;
