import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import './OrderDetails.css';

function OrderDetails() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { order } = state || {};
  const { id: orderId } = useParams();
  const [fullOrder, setFullOrder] = useState(order || null);

  useEffect(() => {
    if (!fullOrder && orderId) {
      const fetchOrder = async () => {
        try {
          // Changed 'firestore' to 'db' here
          const orderRef = doc(db, 'orders', orderId);
          const snap = await getDoc(orderRef);
          if (snap.exists()) setFullOrder({ id: snap.id, ...snap.data() });
        } catch (err) {
          console.error('Error loading order:', err);
        }
      };
      fetchOrder();
    }
  }, [orderId]); // Removed fullOrder from dependencies

  if (!fullOrder) return <p className="loading">Loading order details…</p>;

  const {
    plan,
    status,
    amount,
    transactionID,
    payerEmail,
    applicationData = {},
    downloadLink,
    documents = [],
    usptoStatus,
    orderNumber,
    createdAt,
    updatedAt
  } = fullOrder;

  const formatDate = (timestamp) =>
    timestamp?.seconds
      ? new Date(timestamp.seconds * 1000).toLocaleString()
      : 'N/A';

  return (
    <div className="order-details-container">
      <div className="order-card">
        <h2>📦 Order #{orderNumber || fullOrder.id}</h2>

        <div className="section">
          <h3>🧾 Order Info</h3>
          <p><strong>Plan:</strong> {plan}</p>
          <p>
            <strong>Status:</strong>{' '}
            <span className={`status-tag ${status?.toLowerCase()}`}>{status}</span>
          </p>
          <p><strong>Order Date:</strong> {formatDate(createdAt)}</p>
        </div>

        <div className="section">
          <h3>💡 Patent Application</h3>
          <p><strong>Title:</strong> {applicationData.inventionTitle || 'Untitled Patent'}</p>
          <p><strong>Patent Type:</strong> {applicationData.patentType}</p>
          <p><strong>Field of Invention:</strong> {applicationData.fieldOfInvention}</p>
          <p><strong>Summary:</strong> {applicationData.summary}</p>
        </div>

        <div className="section">
          <h3>💳 Payment</h3>
          <p><strong>Amount Paid:</strong> ${amount}</p>
          <p><strong>Transaction ID:</strong> {transactionID}</p>
          <p><strong>Payer Email:</strong> {payerEmail}</p>
          <p><strong>Last Updated:</strong> {formatDate(updatedAt)}</p>
        </div>

        <div className="section">
          <h3>📄 Application Documents</h3>
          {documents.length > 0 ? (
            <ul>
              {documents.map((docItem, index) => (
                <li key={index} style={{ marginBottom: '6px' }}>
                  <a
                    href={docItem.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="download-link"
                  >
                    📄 {docItem.name}
                  </a>
                </li>
              ))}
            </ul>
          ) : downloadLink ? (
            <a
              href={downloadLink}
              target="_blank"
              rel="noopener noreferrer"
              className="download-link"
            >
              Download Final Document
            </a>
          ) : (
            <p>No application document uploaded yet.</p>
          )}
        </div>

        {plan === 'Premium' && (
          <div className="section">
            <h3>🗂 USPTO Submission</h3>
            <p><strong>Status:</strong> {usptoStatus || 'Not available'}</p>
          </div>
        )}

        <div className="section link-section">
          <button
            onClick={() =>
              navigate('/dashboard/application-details', {
                state: { app: applicationData }
              })
            }
            className="view-app-button"
          >
            View Full Application
          </button>
        </div>

        <button className="back-button" onClick={() => navigate(-1)}>
          ← Back to Orders
        </button>
      </div>
    </div>
  );
}

export default OrderDetails;
