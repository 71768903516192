import React, { useEffect, useState } from 'react';
import { useNavigate, NavLink, Outlet } from 'react-router-dom';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import {
  FiUser,
  FiFilePlus,
  FiFileText,
  FiShoppingCart,
  FiLogOut,
  FiMenu
} from 'react-icons/fi';
import './DashboardLayout.css';

function DashboardLayout() {
  const [user, setUser] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return unsubscribe;
  }, []);

  const handleLogout = async () => {
    await signOut(auth);
    navigate('/login');
  };

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <div className="dashboard-layout">
      <div className="mobile-header">
        <button className="hamburger-button" onClick={toggleSidebar}>
          <FiMenu />
        </button>
        <span>PatentEase Dashboard</span>
      </div>

      <aside className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <div className="sidebar-header">
          <h1>⚙️ PatentEase</h1>
          <p>Hi, {user ? (user.displayName || user.email) : 'Guest'}!</p>
        </div>

        <nav className="menu">
          <ul>
            <li>
              <NavLink to="/dashboard/profile" className={({ isActive }) => isActive ? 'active' : ''}>
                <FiUser /> Profile
              </NavLink>
            </li>
            <li>
              <NavLink to="/dashboard/new-patent" className={({ isActive }) => isActive ? 'active' : ''}>
                <FiFilePlus /> New Patent
              </NavLink>
            </li>
            <li>
              <NavLink to="/dashboard/my-applications" className={({ isActive }) => isActive ? 'active' : ''}>
                <FiFileText /> My Applications
              </NavLink>
            </li>
            <li>
              <NavLink to="/dashboard/orders" className={({ isActive }) => isActive ? 'active' : ''}>
                <FiShoppingCart /> Orders
              </NavLink>
            </li>
          </ul>
        </nav>

        <div className="sidebar-actions">
          <button onClick={handleLogout}>
            <FiLogOut /> Logout
          </button>
        </div>
      </aside>

      <main className="main-content" onClick={() => setIsSidebarOpen(false)}>
        <Outlet />
      </main>
    </div>
  );
}

export default DashboardLayout;
