// src/components/admin/UserList.js
import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import './AdminPage.css';

function UserList() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      const snapshot = await getDocs(collection(db, 'users'));
      setUsers(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      setLoading(false);
    };
    fetchUsers();
  }, []);

  return (
    <div className="admin-page">
      <h2>👥 Registered Users</h2>
      {loading ? (
        <p>Loading users...</p>
      ) : (
        <ul className="info-list">
          {users.map((user) => (
            <li key={user.uid}>
              <strong>{user.displayName || 'No Name'}</strong> — {user.email}
              <br />
              <small>Created: {user.createdAt}</small>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default UserList;
