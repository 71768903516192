import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import { onAuthStateChanged, updateProfile, sendPasswordResetEmail } from 'firebase/auth';
import './Profile.css';

function Profile() {
  const [user, setUser] = useState(null);
  const [newName, setNewName] = useState('');
  const [newPhone, setNewPhone] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        setNewName(currentUser.displayName || '');
      }
    });
    return unsubscribe;
  }, []);

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    setMessage('');

    try {
      if (newName !== user.displayName) {
        await updateProfile(auth.currentUser, {
          displayName: newName
        });
      }
      
      setMessage('Profile updated successfully!');
      setTimeout(() => setMessage(''), 5000);
    } catch (error) {
      console.error('Profile update error:', error);
      setMessage(`Error: ${error.message.replace('Firebase: ', '')}`);
    }
  };

  const handlePasswordReset = async () => {
    try {
      if (!user?.email) {
        setMessage('Error: No email associated with this account');
        return;
      }
      
      await sendPasswordResetEmail(auth, user.email);
      setMessage('Password reset email sent! Check your inbox.');
      setTimeout(() => setMessage(''), 5000);
    } catch (error) {
      setMessage(`Error: ${error.message.replace('Firebase: ', '')}`);
    }
  };

  return (
    <div className="profile-page">
      <h2>My Profile</h2>
      {user && (
        <form onSubmit={handleUpdateProfile} className="profile-form">
          <div className="form-group">
            <label>Name</label>
            <input 
              type="text" 
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              placeholder="Your name"
              required 
            />
          </div>
          
          <div className="form-group">
            <label>Email</label>
            <input 
              type="email" 
              value={user.email} 
              readOnly 
              className="read-only-field"
            />
          </div>

          <div className="form-group">
            <label>Phone Number</label>
            <input 
              type="tel" 
              value={newPhone}
              onChange={(e) => setNewPhone(e.target.value.replace(/[^0-9+()-]/g, ''))}
              placeholder="+1 (123) 456-7890"
              pattern="[+0-9()-\s]{10,}"
            />
          </div>

          <button 
            type="submit" 
            className="update-button"
            disabled={!newName.trim()}
          >
            Save Changes
          </button>
        </form>
      )}

      <div className="password-reset-section">
        <h3>Password Management</h3>
        <button 
          className="reset-password-button"
          onClick={handlePasswordReset}
        >
          Send Password Reset Email
        </button>
        <p className="reset-instructions">
          We'll send a password reset link to your registered email address
        </p>
      </div>

      {message && (
        <div className={`message-banner ${message.includes('Error') ? 'error' : 'success'}`}>
          {message}
        </div>
      )}
    </div>
  );
}

export default Profile;