// src/components/admin/OrderList.js
import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import './AdminPage.css';

function OrderList() {
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      const snapshot = await getDocs(collection(db, 'orders'));
      setOrders(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };
    fetchOrders();
  }, []);

  const formatDate = (timestamp) => 
    timestamp?.seconds ? new Date(timestamp.seconds * 1000).toLocaleDateString() : 'N/A';

  const formatCurrency = (amount) => 
    amount?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) || 'N/A';

  const handleView = (order, e) => {
    e.stopPropagation();
    navigate(`/admin/orders/${order.id}`, { state: { order } });
  };

  return (
    <div className="admin-page">
      <h2>📦 All Orders ({orders.length})</h2>
      <table className="application-table">
        <thead>
          <tr>
            <th className="col-order-number">Order #</th>
            <th className="col-title">Invention Title</th>
            <th className="col-plan">Plan</th>
            <th className="col-amount">Amount</th>
            <th className="col-date">Date</th>
            <th className="col-status">Status</th>
            <th className="col-actions">Actions</th>
          </tr>
        </thead>
        <tbody>
          {orders.map(order => (
            <tr key={order.id}>
              <td className="col-order-number">{order.orderNumber || order.id}</td>
              <td className="col-title">{order.applicationData?.inventionTitle || 'N/A'}</td>
              <td className="col-plan">{order.plan}</td>
              <td className="col-amount">{formatCurrency(order.amount)}</td>
              <td className="col-date">{formatDate(order.orderDate)}</td>
              <td className="col-status">{order.status}</td>
              <td className="col-actions">
                <button
                  className="action-btn view-btn"
                  onClick={(e) => handleView(order, e)}
                  title="View order details"
                >
                  <i className="fas fa-eye"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default OrderList;