// src/components/PatentForm.js
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { auth, db } from "../firebase";
import { collection, addDoc, updateDoc, doc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import "./PatentForm.css";

function PatentForm() {
  const location = useLocation();
  const preloadedData = location.state?.app || null;
  const editing = preloadedData !== null;
  const storage = getStorage(); 

  // Updated formData structure with claims fields added
  const [formData, setFormData] = useState(
    preloadedData || {
      inventors: [
        {
          firstName: "",
          middleName: "",
          lastName: "",
          isMinor: false,
          guardian: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
          },
        },
      ],
      patentType: "",
      inventionTitle: "",
      fieldOfInvention: "",
      background: "",
      summary: "",
      briefDrawingDescription: "",
      detailedDescription: "",
      drawing: null,
      // Claims fields: arrays for independent and dependent claims
      independentClaims: [""],
      dependentClaims: [""],
    }
  );

  const [step, setStep] = useState(1);
  const [maxStep, setMaxStep] = useState(editing ? 10 : 1);
  const [docId, setDocId] = useState(editing ? preloadedData.id : null);

  const navigate = useNavigate();

  // Tab information for steps
  const tabInfo = [
    { step: 1, label: "Inventors" },
    { step: 2, label: "Patent Type" },
    { step: 3, label: "Invention Title" },
    { step: 4, label: "Field" },
    { step: 5, label: "Background" },
    { step: 6, label: "Summary" },
    { step: 7, label: "Brief Drawing" },
    { step: 8, label: "Detailed Description" },
    { step: 9, label: "Drawing Upload" },
    { step: 10, label: "Claims" },
  ];

  const validateStep = () => {
    switch (step) {
      case 1:
        // Validate each inventor's required fields
        for (let inventor of formData.inventors) {
          if (!inventor.firstName || !inventor.lastName) {
            alert("Each inventor must have a First Name and Last Name.");
            return false;
          }
          if (inventor.isMinor) {
            if (
              !inventor.guardian.firstName ||
              !inventor.guardian.lastName ||
              !inventor.guardian.email ||
              !inventor.guardian.phone
            ) {
              alert(
                "For minor inventors, please provide complete guardian details (first name, last name, email, and phone)."
              );
              return false;
            }
          }
        }
        return true;
      case 2:
        if (!formData.patentType) {
          alert("Please select a Patent Type.");
          return false;
        }
        return true;
      case 3:
        if (!formData.inventionTitle) {
          alert("Please provide the Invention Title.");
          return false;
        }
        return true;
      case 4:
        if (!formData.fieldOfInvention) {
          alert("Please provide the Field of Invention.");
          return false;
        }
        return true;
      case 5:
        if (!formData.background) {
          alert("Please provide the Background.");
          return false;
        }
        return true;
      case 6:
        if (!formData.summary) {
          alert("Please provide the Summary.");
          return false;
        }
        return true;
      case 7:
        if (!formData.briefDrawingDescription) {
          alert("Please provide the Brief Drawing Description.");
          return false;
        }
        return true;
      case 8:
        if (!formData.detailedDescription) {
          alert("Please provide the Detailed Description.");
          return false;
        }
        return true;
      case 9:
        if (!formData.drawing) {
          alert("Please upload the Drawing.");
          return false;
        }
        return true;
      case 10:
        // Claims are optional; no validation needed.
        return true;
      default:
        return true;
    }
  };

  const saveStepData = async (updatedData = formData) => {
    try {
      if (docId) {
        await updateDoc(doc(db, "patentApplications", docId), {
          ...updatedData,
          updatedAt: new Date(),
        });
      } else {
        const docRef = await addDoc(collection(db, "patentApplications"), 
          {
            ...updatedData,
            userId: auth.currentUser.uid,
            createdAt: new Date(),
          }
        );
        setDocId(docRef.id);
      }
    } catch (error) {
      alert("Error saving application: " + error.message);
    }
  };

  const handleTabChange = (newStep) => {
    if (!editing && newStep > maxStep) {
      alert("Please complete the previous steps first.");
      return;
    }
    setStep(newStep);
  };

  const handleNext = async () => {
    if (!validateStep()) return;

    // Handle file upload at step 9
    if (step === 9 && formData.drawing instanceof File) {
      try {
        const file = formData.drawing;
        const storageRef = ref(
          storage,
          `drawings/${auth.currentUser.uid}-${Date.now()}-${file.name}`
        );
        const snapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        const updatedFormData = { ...formData, drawing: downloadURL };
        setFormData(updatedFormData);
        await saveStepData(updatedFormData);
      } catch (error) {
        alert("Error uploading file: " + error.message);
        return;
      }
    } else {
      await saveStepData();
    }

    if (!editing && step === maxStep && maxStep < 10) {
      setMaxStep(maxStep + 1);
    }

    if (step < 10) {
      setStep(step + 1);
    } else {
      alert("Application saved successfully.");
      navigate("/dashboard/my-applications");
    }
  };

  const handlePrev = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  // Update inventor or nested guardian fields for a given index
  const handleInventorChange = (index, field, value) => {
    const updatedInventors = formData.inventors.map((inv, i) => {
      if (i === index) {
        if (field.startsWith("guardian.")) {
          const key = field.split(".")[1];
          return {
            ...inv,
            guardian: {
              ...inv.guardian,
              [key]: value,
            },
          };
        } else if (field === "isMinor") {
          return { ...inv, isMinor: value };
        } else {
          return { ...inv, [field]: value };
        }
      }
      return inv;
    });
    setFormData({ ...formData, inventors: updatedInventors });
  };

  const addInventor = () => {
    setFormData({
      ...formData,
      inventors: [
        ...formData.inventors,
        {
          firstName: "",
          middleName: "",
          lastName: "",
          isMinor: false,
          guardian: { firstName: "", lastName: "", email: "", phone: "" },
        },
      ],
    });
  };

  // Functions for handling claim fields
  const handleClaimChange = (claimType, index, value) => {
    const updatedClaims = [...formData[claimType]];
    updatedClaims[index] = value;
    setFormData({ ...formData, [claimType]: updatedClaims });
  };

  const addClaim = (claimType) => {
    setFormData({
      ...formData,
      [claimType]: [...formData[claimType], ""],
    });
  };

  const removeClaim = (claimType, index) => {
    const updatedClaims = formData[claimType].filter((_, i) => i !== index);
    setFormData({ ...formData, [claimType]: updatedClaims });
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="step-content">
            <h3>Step 1: Inventor Information</h3>
            <p className="explanation">
              Please enter the names of all inventors. <br />
              Example: John, Jane, and Alex Doe. For each inventor, First and
              Last Name are required. If an inventor is a minor, please check
              the box and provide guardian details.
            </p>
            {formData.inventors.map((inventor, index) => (
              <div key={index} className="inventor-entry">
                <div className="form-group">
                  <label>
                    First Name: <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Inventor's first name"
                    value={inventor.firstName}
                    onChange={(e) =>
                      handleInventorChange(index, "firstName", e.target.value)
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Middle Name:</label>
                  <input
                    type="text"
                    placeholder="Inventor's middle name (optional)"
                    value={inventor.middleName}
                    onChange={(e) =>
                      handleInventorChange(index, "middleName", e.target.value)
                    }
                  />
                </div>
                <div className="form-group">
                  <label>
                    Last Name: <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Inventor's last name"
                    value={inventor.lastName}
                    onChange={(e) =>
                      handleInventorChange(index, "lastName", e.target.value)
                    }
                  />
                </div>
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      checked={inventor.isMinor}
                      onChange={(e) =>
                        handleInventorChange(index, "isMinor", e.target.checked)
                      }
                    />
                    Inventor is a minor
                  </label>
                </div>
                {inventor.isMinor && (
                  <div className="guardian-section">
                    <h4>Guardian Information</h4>
                    <div className="form-group">
                      <label>
                        Guardian First Name: <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Guardian's first name"
                        value={inventor.guardian.firstName}
                        onChange={(e) =>
                          handleInventorChange(
                            index,
                            "guardian.firstName",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Guardian Last Name: <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Guardian's last name"
                        value={inventor.guardian.lastName}
                        onChange={(e) =>
                          handleInventorChange(
                            index,
                            "guardian.lastName",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Guardian Email: <span className="required">*</span>
                      </label>
                      <input
                        type="email"
                        placeholder="Guardian's email"
                        value={inventor.guardian.email}
                        onChange={(e) =>
                          handleInventorChange(
                            index,
                            "guardian.email",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Guardian Phone: <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Guardian's phone number"
                        value={inventor.guardian.phone}
                        onChange={(e) =>
                          handleInventorChange(
                            index,
                            "guardian.phone",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}
            <button className="add-inventor-button" onClick={addInventor}>
              Add Inventor
            </button>
          </div>
        );
      case 2:
        return (
          <div className="step-content">
            <h3>Step 2: Patent Type</h3>
            <p className="explanation">
              Choose the type of patent you are filing—think of it as selecting
              the "flavor" of your protection plan! <br />
              <strong>Provisional Utility Patent:</strong> A quick, informal way
              to secure a filing date while you refine your invention. <br />
              <strong>Non-Provisional Utility Patent:</strong> A full, in-depth
              application that undergoes examination to assess patentability.{" "}
              <br />
              <strong>Design Patent:</strong> Protects the unique, aesthetic
              design of your product.
            </p>
            <div className="form-group">
              <label>Select Patent Type:</label>
              <select
                value={formData.patentType}
                onChange={(e) =>
                  setFormData({ ...formData, patentType: e.target.value })
                }
              >
                <option value="">-- Select --</option>
                <option value="Provisional Utility Patent">
                  Provisional Utility Patent
                </option>
                <option value="Non-Provisional Utility Patent">
                  Non-Provisional Utility Patent
                </option>
                <option value="Design Patent">Design Patent</option>
              </select>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="step-content">
            <h3>Step 3: Invention Title</h3>
            <p className="explanation">
              Give your invention a catchy, concise title that reflects its
              unique benefits. <br />
              Examples: "Modular Solar-Powered Charger" or "Ergonomic Chair with
              Dynamic Lumbar Support."
            </p>
            <div className="form-group">
              <label>Title:</label>
              <input
                type="text"
                placeholder="Enter invention title"
                value={formData.inventionTitle}
                onChange={(e) =>
                  setFormData({ ...formData, inventionTitle: e.target.value })
                }
                className="tall-input"
              />
            </div>
          </div>
        );
      case 4:
        return (
          <div className="step-content">
            <h3>Step 4: Field of Invention</h3>
            <p className="explanation">
              Field of invention refers to the classification of patents into
              broad classes and narrow subclasses to aid in patent searches. It
              is also known as office classification or field of search. <br />
              Examples: For a patent for a new type of computer software, the
              field of invention might be "Information Processing" with a more
              specific subclass like "Software Applications"..
            </p>
            <div className="form-group">
              <label>Field:</label>
              <input
                type="text"
                placeholder="Enter field of invention"
                value={formData.fieldOfInvention}
                onChange={(e) =>
                  setFormData({ ...formData, fieldOfInvention: e.target.value })
                }
                className="tall-input"
              />
            </div>
          </div>
        );
      case 5:
        return (
          <div className="step-content">
            <h3>Step 5: Background</h3>
            <p className="explanation">
              The "background" section provides context for the invention by
              describing the problem the invention addresses and the existing
              solutions (prior art) that have shortcomings. <br />
              Example: "Current solar chargers are bulky and inefficient. Our
              invention introduces a compact, modular design that maximizes
              energy conversion and ease of installation." <br />
              Recommended: 200-400 words.
            </p>
            <div className="form-group">
              <label>Background:</label>
              <textarea
                placeholder="Enter background information"
                value={formData.background}
                onChange={(e) =>
                  setFormData({ ...formData, background: e.target.value })
                }
                className="tall-textarea"
              />
            </div>
          </div>
        );
      case 6:
        return (
          <div className="step-content">
            <h3>Step 6: Summary</h3>
            <p className="explanation">
              The "Summary" section provides a concise overview of the
              invention, highlighting its nature, purpose, and benefits, serving
              as a quick introduction for readers. <br />
              Example: "This invention relates to a foldable display device for
              mobile phones. The device includes a flexible display panel that
              can be folded and unfolded, enabling the device to transition
              between a compact, portable form and a larger, tablet-like form.
              This provides users with increased screen real estate when needed,
              while maintaining a compact form factor for portability. The
              device also includes a hinge mechanism that allows for smooth and
              reliable folding and unfolding of the display." <br />
              Recommended: 100-200 words.
            </p>
            <div className="form-group">
              <label>Summary:</label>
              <textarea
                placeholder="Enter summary"
                value={formData.summary}
                onChange={(e) =>
                  setFormData({ ...formData, summary: e.target.value })
                }
                className="tall-textarea"
              />
            </div>
          </div>
        );
      case 7:
        return (
          <div className="step-content">
            <h3>Step 7: Brief Drawing Description</h3>
            <p className="explanation">
              "Brief Drawing Description" in a patent application is a concise
              summary of what each figure in the accompanying drawings depicts,
              essentially acting as a key to understanding the visual
              representation of the invention. It typically includes the figure
              number and a short explanation of what that view shows, allowing
              the patent examiner to quickly grasp the key aspects of the
              invention as illustrated in the drawings. <br />
              Examples: "Figure 1: A perspective view of the novel widget,
              showing the main body (labeled "1") with attached components A and
              B. Figure 2: A cross-sectional view of the widget along line 2-2
              in Figure 1, illustrating the internal mechanism of component A."
            </p>
            <div className="form-group">
              <label>Brief Drawing Description:</label>
              <textarea
                placeholder="Enter brief drawing description"
                value={formData.briefDrawingDescription}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    briefDrawingDescription: e.target.value,
                  })
                }
                className="tall-textarea"
              />
            </div>
          </div>
        );
      case 8:
        return (
          <div className="step-content">
            <h3>Step 8: Detailed Description</h3>
            <p className="explanation">
              "Detailed Description" is a comprehensive written explanation of
              the invention, outlining how it works, how to make it, and its key
              features, ensuring that someone skilled in the relevant field
              could understand and replicate the invention based on the
              information provided. <br />
              Example: "The present invention describes a protective smartphone
              case comprising a flexible outer shell made from a blend of
              impact-resistant polymer and a shock-absorbing inner layer made of
              a proprietary elastomer. The outer shell is designed with a
              textured surface for enhanced grip, while the inner layer features
              a honeycomb structure to distribute impact forces. The case
              includes integrated buttons for easy access to phone controls and
              a raised lip around the screen to protect against scratches when
              placed face down. To assemble the case, the user simply needs to
              slide the phone into the inner layer and then snap on the outer
              shell, ensuring a secure fit. [Figure 1 illustrates the overall
              design, while Figure 2 shows a cross-section of the internal
              shock-absorbing layer]." <br />
              Recommended: 500-1000 words.
            </p>
            <div className="form-group">
              <label>Detailed Description:</label>
              <textarea
                placeholder="Enter detailed description"
                value={formData.detailedDescription}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    detailedDescription: e.target.value,
                  })
                }
                className="tall-textarea"
              />
            </div>
          </div>
        );
      case 9:
        return (
          <div className="step-content">
            <h3>Step 9: Drawing Upload (Optional)</h3>
            <p className="explanation">
              Upload a high-quality image (JPEG or PNG) of your invention’s
              drawing. This step is optional—if you don't have a drawing ready,
              you can continue and we'll help you create one.
            </p>
            <div className="form-group">
              <label>Upload Drawing:</label>
              <input
                type="file"
                accept="image/*"
                onChange={(e) =>
                  setFormData({ ...formData, drawing: e.target.files[0] })
                }
              />
            </div>
          </div>
        );

      case 10:
        return (
          <div className="step-content">
            <h3>Step 10: Claims</h3>
            <p className="explanation">
              Claims define the legal scope of your patent protection. An{" "}
              <strong>independent claim</strong> is a broad, standalone claim
              that outlines the essential features of your invention. For
              example: <br />
              <em>
                "A modular solar charger comprising a plurality of adjustable
                photovoltaic panels configured in a flexible array."
              </em>{" "}
              <br />
              <br />A <strong>dependent claim</strong> builds on an independent
              claim by adding specific limitations or details. For example:{" "}
              <br />
              <em>
                "The modular solar charger of claim 1, wherein each photovoltaic
                panel is rotatable to optimize sun exposure."
              </em>{" "}
              <br />
              <br />
              <em>
                If you're not sure how to draft these, leave them blank—we'll
                help you craft them later.
              </em>{" "}
              <br />
              <strong>Note:</strong> The application fee includes up to 3
              independent claims and up to 20 total claims. Additional claims
              may incur extra fees.
            </p>
            <div className="claims-section">
              <h4>Independent Claims</h4>
              {formData.independentClaims.map((claim, index) => (
                <div key={index} className="claim-entry">
                  <textarea
                    rows="2"
                    placeholder="Enter independent claim"
                    value={claim}
                    onChange={(e) =>
                      handleClaimChange(
                        "independentClaims",
                        index,
                        e.target.value
                      )
                    }
                    className="claim-input"
                  />
                  {formData.independentClaims.length > 1 && (
                    <button
                      type="button"
                      className="remove-claim-button"
                      onClick={() => removeClaim("independentClaims", index)}
                    >
                      Delete
                    </button>
                  )}
                </div>
              ))}
              <button
                type="button"
                className="add-claim-button"
                onClick={() => addClaim("independentClaims")}
              >
                Add Independent Claim
              </button>
            </div>
            <div className="claims-section">
              <h4>Dependent Claims</h4>
              {formData.dependentClaims.map((claim, index) => (
                <div key={index} className="claim-entry">
                  <textarea
                    rows="2"
                    placeholder="Enter dependent claim"
                    value={claim}
                    onChange={(e) =>
                      handleClaimChange(
                        "dependentClaims",
                        index,
                        e.target.value
                      )
                    }
                    className="claim-input"
                  />
                  {formData.dependentClaims.length > 1 && (
                    <button
                      type="button"
                      className="remove-claim-button"
                      onClick={() => removeClaim("dependentClaims", index)}
                    >
                      Delete
                    </button>
                  )}
                </div>
              ))}
              <button
                type="button"
                className="add-claim-button"
                onClick={() => addClaim("dependentClaims")}
              >
                Add Dependent Claim
              </button>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const renderTabs = () => {
    return (
      <div className="tabs">
        {tabInfo.map((tab) => (
          <button
            key={tab.step}
            className={`tab ${tab.step === step ? "active" : ""}`}
            onClick={() => handleTabChange(tab.step)}
          >
            {`Step ${tab.step}: ${tab.label}`}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="patent-form-container">
      {renderTabs()}
      <div className="form-content">{renderStep()}</div>
      <div className="form-navigation">
        {step > 1 && <button onClick={handlePrev}>Previous</button>}
        <button onClick={handleNext}>
          {step === 10 ? "Save & Exit" : "Save & Next"}
        </button>
      </div>
    </div>
  );
}

export default PatentForm;
