// src/components/Footer.js
import React, { useState } from "react";
import "./Footer.css";
import { db } from "../firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

function Footer() {
  const currentYear = new Date().getFullYear();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setMessage("Please enter a valid email.");
      return;
    }
    try {
      await addDoc(collection(db, "subscribers"), {
        email,
        subscribedAt: serverTimestamp(),
      });
      setMessage("Thanks for subscribing!");
      setEmail("");
    } catch (error) {
      console.error("Subscription error:", error);
      setMessage("Subscription failed. Please try again.");
    }
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h4>Company</h4>
          <ul>
            <li>
              <a href="/about" target="_blank" rel="noopener noreferrer">
                About
              </a>
            </li>
            <li>
              <a href="/contact" target="_blank" rel="noopener noreferrer">
                Contact
              </a>
            </li>
            <li>
              <a href="/investors" target="_blank" rel="noopener noreferrer">
                Investors
              </a>
            </li>
            <li>
              <a href="/press" target="_blank" rel="noopener noreferrer">
                Press
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Legal</h4>
          <ul>
            <li>
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                href="/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
            </li>
            <li>
              <a href="/disclaimer" target="_blank" rel="noopener noreferrer">
                Disclaimer
              </a>
            </li>
            <li>
              <a
                href="/cookie-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cookie Policy
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>Information</h4>
          <ul>
            <li>
              <a href="/faq" target="_blank" rel="noopener noreferrer">
                FAQ
              </a>
            </li>
            <li>
              <a href="/resources" target="_blank" rel="noopener noreferrer">
                Resources & Blog
              </a>
            </li>
            <li>
              <a href="/useful-links" target="_blank" rel="noopener noreferrer">
                Useful Links
              </a>
            </li>
          </ul>
        </div>
        {/* New Subscription Section */}
        <div className="footer-section">
          <h4>Subscribe to our Newsletter</h4>
          <form onSubmit={handleSubmit} className="subscription-form">
            <input
              type="email"
              className="newsletter-input"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <button type="submit" className="subscribe-button">
              Subscribe
            </button>
          </form>
          {message && <p className="subscription-message">{message}</p>}
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2025 Patent Ease. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
