import React from 'react';
import StaticHeader from './StaticHeader';
import './StaticPages.css';

function Disclaimer() {
  return (
    <div className="static-page">
      <StaticHeader />
      <h1>Disclaimer</h1>
      <p>Effective Date: March 28, 2025</p>

      <p>
        The information provided on Patent Ease (the "Site") is for general informational purposes only. All information on the Site is provided in good faith; however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site.
      </p>

      <h2>1. Professional Advice</h2>
      <p>
        The Site does not constitute legal, financial, or professional advice. Users should consult with qualified professionals regarding their specific circumstances before relying on any information contained on the Site.
      </p>

      <h2>2. External Links Disclaimer</h2>
      <p>
        The Site may contain links to external websites that are not operated by Patent Ease. We do not have control over the content and practices of these sites, and we cannot be held responsible for their privacy practices or the accuracy of their information.
      </p>

      <h2>3. Limitation of Liability</h2>
      <p>
        In no event shall Patent Ease, its directors, employees, partners, agents, suppliers, or affiliates be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Site; (ii) any conduct or content of any third party on the Site; or (iii) any unauthorized access, use, or alteration of your transmissions or content.
      </p>

      <h2>4. No Warranties</h2>
      <p>
        The Site is provided on an "AS IS" and "AS AVAILABLE" basis without any warranties of any kind. Patent Ease expressly disclaims all warranties, whether express or implied, including but not limited to the warranties of merchantability, fitness for a particular purpose, and non-infringement.
      </p>

      <h2>5. Changes to This Disclaimer</h2>
      <p>
        We reserve the right to update or modify this Disclaimer at any time. Your continued use of the Site after any such changes constitutes your acceptance of the new Disclaimer.
      </p>

      <h2>6. Contact Us</h2>
      <p>
        If you have any questions about this Disclaimer, please contact us at:
      </p>
      <p>
        <strong>Email:</strong> <a href="mailto:support@patent-ease.com">support@patent-ease.com</a><br />
        <strong>Phone:</strong> (909) 200-4050<br />
        <strong>Address:</strong> 1252 S Diamond Bar Blvd, Unit D, Diamond Bar, CA 91765
      </p>
    </div>
  );
}

export default Disclaimer;
