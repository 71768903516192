import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../firebase";
import "../OrderDetails.css";

function OrderDetailsAdmin() {
  const { state } = useLocation();
  const { order } = state || {};
  const { id: orderId } = useParams();
  const [fullOrder, setFullOrder] = useState(order || null);
  const [status, setStatus] = useState(order?.status || "");
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!fullOrder && orderId) {
      const fetchOrder = async () => {
        const snap = await getDoc(doc(db, "orders", orderId));
        if (snap.exists()) {
          const data = snap.data();
          setFullOrder({ id: snap.id, ...data });
          setStatus(data.status);
        }
      };
      fetchOrder();
    }
  }, [orderId, fullOrder]);

  if (!fullOrder) return <p className="loading">Loading order details…</p>;

  const updateOrder = async () => {
    const updates = { status };
    if (file) {
      const fileRef = ref(storage, `finalDocs/${fullOrder.id}-${file.name}`);
      await uploadBytes(fileRef, file);
      const url = await getDownloadURL(fileRef);
      updates.downloadLink = url;
    }
    await updateDoc(doc(db, "orders", fullOrder.id), updates);
    alert("Order updated successfully");
  };

  const formatDate = (timestamp) =>
    timestamp?.seconds
      ? new Date(timestamp.seconds * 1000).toLocaleString()
      : "N/A";

  const {
    plan,
    amount,
    transactionID,
    payerEmail,
    applicationData = {},
    applicationLink,
    applicationId,
    downloadLink,
    orderNumber,
    createdAt,
    updatedAt,
  } = fullOrder;

  return (
    <div className="order-details-container">
      <div className="order-card">
        <h2>📦 Order #{orderNumber || fullOrder.id}</h2>

        <div className="section">
          <h3>🧾 Order Info</h3>
          <p>
            <strong>Plan:</strong> {plan}
          </p>
          <p>
            <strong>Status:</strong>{" "}
            <select value={status} onChange={(e) => setStatus(e.target.value)}>
              <option value="Pending">Pending</option>
              <option value="Processing">Processing</option>
              <option value="Completed">Completed</option>
            </select>
          </p>
          <p>
            <strong>Order Date:</strong> {formatDate(createdAt)}
          </p>
        </div>

        <div className="section">
          <h3>💡 Patent Application</h3>
          <p>
            <strong>Title:</strong>{" "}
            {applicationData.inventionTitle || "Untitled Patent"}
          </p>
          <p>
            <strong>Patent Type:</strong> {applicationData.patentType}
          </p>
          <p>
            <strong>Field of Invention:</strong>{" "}
            {applicationData.fieldOfInvention}
          </p>
          <p>
            <strong>Summary:</strong> {applicationData.summary}
          </p>

          <div style={{ marginTop: "10px" }}>
            {applicationId ? (
              <button
                className="view-app-button"
                onClick={() => navigate(`/admin/applications/${applicationId}`)}
              >
                📝 View Full Application
              </button>
            ) : (
              <button
                className="view-app-button"
                onClick={() =>
                  navigate(`/admin/applications/temp`, {
                    state: { app: applicationData },
                  })
                }
              >
                📝 View Full Application
              </button>
            )}
          </div>
        </div>

        <div className="section">
          <h3>💳 Payment</h3>
          <p>
            <strong>Amount:</strong> ${amount}
          </p>
          <p>
            <strong>Transaction:</strong> {transactionID}
          </p>
          <p>
            <strong>Email:</strong> {payerEmail}
          </p>
          <p>
            <strong>Last Updated:</strong> {formatDate(updatedAt)}
          </p>
        </div>

        <div className="section">
          <h3>📄 Application Documents</h3>

          {fullOrder.documents && fullOrder.documents.length > 0 ? (
            <ul>
              {fullOrder.documents.map((docItem, index) => (
                <li key={index} style={{ marginBottom: "8px" }}>
                  <a
                    href={docItem.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      marginRight: "10px",
                      color: "#007bff",
                      textDecoration: "underline",
                    }}
                  >
                    📄 {docItem.name}
                  </a>
                  <button
                    onClick={async () => {
                      const confirmDelete = window.confirm(
                        `Delete ${docItem.name}?`
                      );
                      if (!confirmDelete) return;

                      try {
                        const fileRef = ref(
                          storage,
                          `finalDocs/${fullOrder.id}-${docItem.name}`
                        );
                        await fileRef.delete?.(); // Delete from Storage

                        const updatedDocs = fullOrder.documents.filter(
                          (_, i) => i !== index
                        );
                        await updateDoc(
                          doc(db, "orders", fullOrder.id),
                          {
                            documents: updatedDocs,
                          }
                        );
                        setFullOrder((prev) => ({
                          ...prev,
                          documents: updatedDocs,
                        }));
                      } catch (err) {
                        console.error("Delete failed:", err);
                        alert("Failed to delete document.");
                      }
                    }}
                    style={{
                      backgroundColor: "#f44336",
                      color: "#fff",
                      border: "none",
                      padding: "4px 8px",
                    }}
                  >
                    🗑 Delete
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p>No documents uploaded yet.</p>
          )}
        </div>

        <div className="section">
          <h3>📤 Upload New Document</h3>
          <input
            type="file"
            accept="application/pdf"
            onChange={async (e) => {
              const file = e.target.files[0];
              if (!file) return;

              try {
                const fileRef = ref(
                  storage,
                  `finalDocs/${fullOrder.id}-${file.name}`
                );
                await uploadBytes(fileRef, file);
                const url = await getDownloadURL(fileRef);

                const newDoc = { name: file.name, url };
                const updatedDocs = [...(fullOrder.documents || []), newDoc];

                await updateDoc(doc(db, "orders", fullOrder.id), {
                  documents: updatedDocs,
                });

                setFullOrder((prev) => ({ ...prev, documents: updatedDocs }));
                alert("Document uploaded.");
              } catch (err) {
                console.error("Upload failed:", err);
                alert("Failed to upload document.");
              }
            }}
          />
        </div>

        <button onClick={updateOrder} className="view-app-button">
          💾 Save Changes
        </button>

        <div className="section link-section">
          <button
            className="back-button"
            onClick={() => navigate("/admin/orders")}
          >
            ← Back to Orders
          </button>
        </div>
      </div>
    </div>
  );
}

export default OrderDetailsAdmin;
