// src/components/MyApplications.js
import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { collection, query, where, getDocs, deleteDoc, doc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import "./MyApplications.css";

function MyApplications() {
  const [applications, setApplications] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const q = query(
          collection(db, "patentApplications"),  // Changed firestore to db
          where("userId", "==", auth.currentUser.uid)
        );
        const querySnapshot = await getDocs(q);
        const apps = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setApplications(apps);
      } catch (error) {
        console.error("Error fetching applications: ", error);
      }
    };
    fetchApplications();
  }, [navigate]);

  const handleDelete = async (app) => {
    // Prevent deletion if the application is submitted or already has an associated order.
    if (app.submitted || app.orderId) {
      alert(
        "This application has already been submitted and cannot be deleted. There's an order for this application."
      );
      return;
    }
    if (window.confirm("Are you sure you want to delete this application?")) {
      if (window.confirm("This action cannot be undone. Confirm delete?")) {
        try {
          await deleteDoc(doc(db, "patentApplications", app.id));  // Changed firestore to db
          setApplications(applications.filter((a) => a.id !== app.id));
        } catch (error) {
          console.error("Error deleting application: ", error);
        }
      }
    }
  };

  return (
    <div className="applications-container">
      <h2>📄 My Patent Applications</h2>
      {applications.length === 0 ? (
        <p className="empty-message">You have no saved applications yet.</p>
      ) : (
        <div className="applications-grid">
          {applications.map((app) => {
            const title = app.inventionTitle || "Untitled Patent";
            const firstInventor = app.inventors?.[0];
            const applicant = firstInventor
              ? `${firstInventor.firstName || ""} ${
                  firstInventor.middleName ? firstInventor.middleName + " " : ""
                }${firstInventor.lastName || ""}`.trim()
              : "Unknown Inventor";
            const type = app.patentType || "Unknown Type";
            let createdDate = "N/A";
            if (app.createdAt?.seconds) {
              const d = new Date(app.createdAt.seconds * 1000);
              if (!isNaN(d)) createdDate = d.toLocaleDateString();
            }

            return (
              <div key={app.id} className="application-card">
                <div
                  onClick={() =>
                    navigate("/dashboard/application-details", { state: { app } })
                  }
                  style={{ cursor: "pointer" }}
                >
                  <h3 className="application-title">{title}</h3>
                  <p className="application-meta">
                    <strong>Inventor:</strong> {applicant}
                  </p>
                  <p className="application-meta">
                    <strong>Type:</strong> {type}
                  </p>
                  <p className="application-meta">
                    <strong>Created:</strong> {createdDate}
                  </p>
                  {app.submitted && (
                    <span className="submitted-tag">✅ Submitted</span>
                  )}
                </div>
                <button
                  className="delete-button"
                  onClick={() => handleDelete(app)}
                >
                  🗑 Delete
                </button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default MyApplications;
