// src/components/UsefulLinks.js
import React from 'react';
import StaticHeader from './StaticHeader';
import './StaticPages.css';

function UsefulLinks() {
  return (
    <div className="static-page">
      <StaticHeader />
      <h1>Useful Patent Resources</h1>
      <p>
        Welcome to our resource center! Below is a curated list of reliable, public websites designed to help you navigate the US patent application process and perform effective patent searches.
      </p>

      <h2>USPTO Official Resources</h2>
      <ul>
        <li>
          <a
            href="https://www.uspto.gov/patents"
            target="_blank"
            rel="noopener noreferrer"
          >
            United States Patent and Trademark Office (USPTO)
          </a>
        </li>
        <li>
          <a
            href="https://www.uspto.gov/patents/basics"
            target="_blank"
            rel="noopener noreferrer"
          >
            USPTO Patent Basics
          </a>
        </li>
        <li>
          <a
            href="https://www.uspto.gov/patents/process"
            target="_blank"
            rel="noopener noreferrer"
          >
            Patent Process
          </a>
        </li>
        <li>
          <a
            href="https://www.uspto.gov/patents/process/search"
            target="_blank"
            rel="noopener noreferrer"
          >
            USPTO Patent Search Guide
          </a>
        </li>
        <li>
          <a
            href="https://www.uspto.gov/patents/resources/types/utility.jsp"
            target="_blank"
            rel="noopener noreferrer"
          >
            Utility Patent Application Guide
          </a>
        </li>
        <li>
          <a
            href="https://www.uspto.gov/trademarks"
            target="_blank"
            rel="noopener noreferrer"
          >
            Trademark Assistance Center &amp; Help Videos
          </a>
        </li>
        <li>
          <a
            href="https://www.uspto.gov/inventors/assessment"
            target="_blank"
            rel="noopener noreferrer"
          >
            IP Awareness Assessment Tool
          </a>
        </li>
        <li>
          <a
            href="https://www.uspto.gov/inventors"
            target="_blank"
            rel="noopener noreferrer"
          >
            Inventor and Entrepreneur Resources
          </a>
        </li>
        <li>
          <a
            href="https://www.uspto.gov/PatentMicroentity"
            target="_blank"
            rel="noopener noreferrer"
          >
            Micro Entity Limit Information
          </a>
        </li>
        <li>
          <a
            href="https://www.uspto.gov/inventors/proseprobono"
            target="_blank"
            rel="noopener noreferrer"
          >
            Patent Pro Bono Help &amp; Videos
          </a>
        </li>
        <li>
          <a
            href="https://www.uspto.gov/aia_implementation/patents.jsp#heading-10"
            target="_blank"
            rel="noopener noreferrer"
          >
            First Inventor to File
          </a>
        </li>
      </ul>

      <h2>Patent Search Tools</h2>
      <ul>
        <li>
          <a
            href="https://patents.google.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Patents
          </a>
        </li>
        <li>
          <a
            href="https://www.freepatentsonline.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Free Patents Online
          </a>
        </li>
        <li>
          <a
            href="https://www.uspto.gov/patents/search"
            target="_blank"
            rel="noopener noreferrer"
          >
            USPTO Patent Search
          </a>
        </li>
      </ul>

      <p>
        For further assistance or more information, please contact us at{' '}
        <a href="mailto:support@patent-ease.com">support@patent-ease.com</a>.
      </p>
    </div>
  );
}

export default UsefulLinks;
